import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

const FundWalletModal = (props) => {
  const { register, control } = useForm();
  const amount = useWatch({
    control,
    name: "amount", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  useEffect(() => {
    props.data(amount);
  }, [amount, props]);

  return (
    <form>
      <label className="label">Amount</label>
      <input type="number" name="amount" ref={register()} className="input" />
    </form>
  );
};

export default FundWalletModal;
