import axios from "axios";
import Toast from "../components/Toast";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
const baseUrl = process.env.REACT_APP_API_BASE_URL;

axios.defaults.withCredentials = true;
export const app = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": baseUrl,
    "Access-Control-Allow-Credentials": true,
  },
});

export function apiRequest(path, method = "GET", data) {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  return app
    .request({
      url: `${apiBaseUrl}/${path}`,
      method,
      data: method !== "GET" ? data : null,
      cancelToken: source.token,
    })
    .then((response) => {
      if (!response.data.data) {
        // Automatically signout user if accessToken is no longer valid
        if (response.status === 401) {
          console.log("unauthorized. logging out...");
          sessionStorage.setItem("user", "false");
          window.location = "/auth/signin";
        }
        throw new CustomError(response.status, response.message);
      } else {
        return response.data.data;
      }
    })
    .catch((error) => {
      if (error.response.status === 401) {
        console.log("unauthorized. logging out...");
        sessionStorage.setItem("user", "false");
        window.location = "/auth/signin";
      }
      if (error.response.status === 500) {
        Toast(
          "Sorry! Something went wrong on our end, please try again",
          "is-danger"
        );
      }
      return error.response;
    });
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}
