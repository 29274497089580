import React, { useEffect, useState } from "react";
import FormField from "./FormField";
import { useAuth } from "./../util/auth.js";
import { useRouter } from "../util/router";
import { useForm } from "react-hook-form";
import Toast from "./Toast";
import mixpanel from "mixpanel-browser";

function       AuthForm(props) {
  const auth = useAuth();
  const router = useRouter();

  const [pending, setPending] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [token, setToken] = useState();
  const [recoveryToken, setRecoverToken] = useState();
  const { handleSubmit, register, errors, getValues } = useForm();

  useEffect(() => {
    router.query.phone && setPhoneNumber(router.query.phone);
    router.query.token && setToken(router.query.token);
    router.query.recoveryToken &&
      validateRecoveryToken(router.query.recoveryToken);
  }, [router]);

  const submitHandlersByType = {
    verify: async ({ phone }) => {
      await auth.checkPhoneNumber(phone);
      props.onVerify();
    },

    signup: async ({ pass, email, firstname }) => {
      const user = await auth.signup({
        phone: phoneNumber,
        pass,
        email,
        firstname,
        token,
      });
      setPending(false);
      props.onAuth(user);
      mixpanel.track('New sign up');
      return user;
    },

    otp: async ({ otp }) => {
      const result = await auth.verifyPin(otp);
      props.onOtp(result);
      setPhoneNumber(result.msisdn);
      return result;
    },

    signin: async ({ phone, pass }) => {
      const user = await auth.signin(phone, pass);
      setPending(false);
      props.onAuth(user);
      mixpanel.track('New log in');
      return user;
    },

    forgotpass: ({ email }) => {
      return auth
        .sendPasswordResetEmail(email)
        .then(() => {
          setPending(false);
          // Show success alert message
          props.onFormAlert({
            type: "success",
            message: "Password reset email sent",
          });
        })
        .catch((error) => {
          setPending(false);
          props.onFormAlert({
            type: "error",
            message: error.message,
          });
        });
    },

    changepass: ({ pass }) => {
      return auth
        .confirmPasswordReset({ pass, code: recoveryToken })
        .then(() => {
          setPending(false);
          // Show success alert message
          props.onFormAlert({
            type: "success",
            message: "Your password has been changed",
          });
        })
        .catch((error) => {
          setPending(false);
          props.onFormAlert({
            type: "error",
            message: error.message,
          });
        });
    },
  };

  // verify password recovery token
  const validateRecoveryToken = async (token) => {
    const result = await auth.validateRecoveryToken(token);
    if (result) {
      setRecoverToken(result.stage2Token);
    } else {
      Toast(
        "Validation failed. Kindly request for a new recovery email",
        "is-danger"
      );
    }
  };

  // Handle form submission
  const onSubmit = ({ email, firstname, phone, pass, otp }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[props.type]({
      firstname,
      email,
      phone: "234" + phone?.trim().slice(1),
      pass,
      otp,
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      props.onFormAlert({
        type: "error",
        message: error.message,
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {["signup", "signin", "verify"].includes(props.type) && (
        <FormField
          name="phone"
          type="tel"
          placeholder="Phone: 08012345678"
          size="medium"
          value={phoneNumber && phoneNumber}
          disabled={phoneNumber}
          error={errors.phone}
          inputRef={register({
            required: "Please enter your phone number",
            validate: (value) => {
              if (value.trim().length === 11) {
                return true;
              } else {
                return "Your number needs to be 11 digits like this: 08012345678";
              }
            },
          })}
        />
      )}

      {["otp"].includes(props.type) && (
        <FormField
          name="otp"
          type="number"
          placeholder="Enter your OTP number"
          size="medium"
          error={errors.otp}
          inputRef={register({
            required: "Please enter the otp number you received on your phone",
          })}
        />
      )}

      {["signup"].includes(props.type) && (
        <FormField
          name="email"
          type="email"
          placeholder="Email"
          size="medium"
          error={errors.email}
          inputRef={register({})}
        />
      )}
      {["forgotpass"].includes(props.type) && (
        <FormField
          name="email"
          type="email"
          placeholder="Email"
          size="medium"
          error={errors.email}
          inputRef={register({ required: "Please enter your email" })}
        />
      )}

      {["signup"].includes(props.type) && (
        <FormField
          name="firstname"
          type="text"
          placeholder="First Name"
          size="medium"
          error={errors.firstname}
          inputRef={register({})}
        />
      )}

      {["signup", "signin", "changepass"].includes(props.type) && (
        <FormField
          name="pass"
          type="password"
          placeholder="Password"
          size="medium"
          error={errors.pass}
          inputRef={register({
            required: "Please enter a password",
          })}
        />
      )}

      {["signup", "changepass"].includes(props.type) && (
        <FormField
          name="confirmPass"
          type="password"
          placeholder="Confirm Password"
          size="medium"
          error={errors.confirmPass}
          inputRef={register({
            required: "Please enter your password again",
            validate: (value) => {
              if (value === getValues().pass) {
                return true;
              } else {
                return "This doesn't match your password";
              }
            },
          })}
        />
      )}

      <div className="field">
        <p className="control ">
          <button
            className={
              "button is-medium is-fullwidth" +
              (props.buttonColor ? ` is-${props.buttonColor}` : "") +
              (props.buttonInverted ? " is-inverted" : "") +
              (pending ? " is-loading" : "")
            }
            type="submit"
          >
            {props.typeValues.buttonText}
          </button>
        </p>
      </div>
    </form>
  );
}

export default AuthForm;
