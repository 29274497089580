import React from "react";

const LoanPreviewModal = (props) => {
  const { data } = props;

  const handleChange = (event) => {
    props.checked(event.target.checked);
  };
  return (
    <div>
      <table class="table">
        <tbody>
          <tr>
            <td>
              <span className="primary-color">Loan Amount</span>
            </td>
            <td>
              <span className="primary-color">
                <i className="mdi mdi-currency-ngn"></i>
                {data?.amount}
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="primary-color">Interest Rate</span>
            </td>
            <td>
              <span className="primary-color">
                {data?.type === "lending"
                  ? data?.lenderInterestRate
                  : data?.borrowerInterestRate}
                %
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="primary-color">Interest Amount</span>
            </td>
            <td>
              <span className="primary-color">
                <i className="mdi mdi-currency-ngn"></i>
                {data?.type == "lending"
                  ? data?.lenderExpInterest
                  : data?.paymentsDue - data?.amount}
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="primary-color">
                {data?.type === "lending" ? "Fee" : "Repayment Amount"}
              </span>
            </td>
            <td>
              <span className="primary-color">
                <i className="mdi mdi-currency-ngn"></i>
                {data?.type === "lending"
                  ? data?.lenderExpInterest - data?.lenderActInterest
                  : data?.paymentsDue}
              </span>
            </td>
          </tr>

          {data?.type === "borrow" && (
            <tr>
              <td>
                <span className="primary-color">Amount Receivable</span>
              </td>
              <td>
                <span className="primary-color">
                  <i className="mdi mdi-currency-ngn"></i>
                  {data?.lendersDue}
                </span>
              </td>
            </tr>
          )}

          <tr>
            <td>
              <span className="primary-color">Tenor</span>
            </td>
            <td>
              <span className="primary-color">
                {data?.tenor} {data?.tenorUnit}
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <span className="primary-color">Due Date</span>
            </td>
            <td>
              <span className="primary-color">
                {data?.dueDate?.dueDate?.split("T")[0]}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        className="py-1 px-2 mb-3"
        style={{ background: "#F4F5FE", borderRadius: "7px" }}
      >
        <input
          type="checkbox"
          name="check"
          onChange={handleChange}
          className="primary-color"
        />
        <span className="pl-2">I have read and agreed to the terms</span>
      </div>
    </div>
  );
};

export default LoanPreviewModal;
