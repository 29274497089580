const columns = [
  {
    field: "createdAt",
    title: "Date",
    render: (value) => `{
      value.split("T")[0];
    }`,
  },
  {
    field: "narration",
    title: "Description",
  },
  {
    field: "amount",
    title: "Amount",
    render: (value) => `N${value}`,
  },
  {
    field: "senderName",
    title: "Sender",
  },
  {
    field: "beneficiaryName",
    title: "Beneficial",
  },
];

export { columns };
