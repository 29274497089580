import React from "react";
import "./RowCard.css";

export default function RowCard({ image, title, subtitle, bgColor }) {
  return (
    <div
      className="rowCard"
      style={{
        backgroundColor: bgColor ? bgColor : "white",
        flexDirection: bgColor ? "row-reverse" : "row",
      }}
    >
      <div className="rowCard__text">
        <div className="rowCard__textBox">
          <h4
            style={{
              color: bgColor ? "white" : "#001E5A",
              // marginLeft: bgColor ? "30px" : 0,
              fontSize: 20,
            }}
          >
            {title}
          </h4>
         
          <p
            style={{
              color: bgColor ? "#F8F9FE" : "#62666C",
              // marginLeft: bgColor ? "30px" : 0,
            }}
          >
            {subtitle}
          </p>
        </div>
      </div>

      <div className="rowCard__imgBox">
        <img src={image} alt="" className="rowCard__img" />
      </div>
    </div>
  );
}
