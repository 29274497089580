import React, { useState } from "react";

import { Footer, Hero, HowItWorks, WhyTender } from "../components/new_design";
import mixpanel from "mixpanel-browser";

function IndexPage(props) {
  const [activeSwitch, setActiveSwitch] = useState(true);

  mixpanel.init('6cafc4b031bdf5c87698c031c373d16e');
  mixpanel.track('Visit Landing Page');

  return (
    <>
      <Hero />

      <HowItWorks />

      <WhyTender />

      <Footer />
    </>
  );
}

export default IndexPage;
