import React, { useEffect, useState } from "react";
import { useAuth } from "../util/auth.js";
import "./AppNavbar.scss";
import Notification from "./Notification.js";
import { getNotifications } from "../util/user.js";

function AppNavbar(props) {
  const auth = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchNotifications = async () => {
      const result = await getNotifications();
      setNotifications(result.notifications);
    };

    return () => (mounted = false);
  }, []);

  return (
    <nav
      className={
        "navbar is-fixed-top pt-3 AppNavbar" +
        (props.color ? ` is-${props.color}` : "") +
        (props.spaced ? " is-spaced" : "")
      }
    >
      <div className="container">
        <div className={"navbar-menu is-active"}>
          <div className="navbar-end">
            <Notification data={notifications} />

            {/* profile icon */}
            <div className="Notification dropdown is-hoverable mr-3">
              <div className="dropdown-trigger">
                <figure
                  className="image is-32x32 is-clickable"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu2"
                >
                  <i className="mdi mdi-account-circle-outline grey is-size-4"></i>
                </figure>
              </div>
              <div
                className="Notification__menu dropdown-menu"
                id="dropdown-menu2"
                role="menu"
              >
                {/* <div className="dropdown-content"> */}
                <button
                  className="button card no-border dropdown-item mt-0"
                  style={{ width: "7rem", left: "4rem" }}
                  onClick={() => auth.signout()}
                >
                  <span className="icon is-small">
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  <span>Signout</span>
                </button>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AppNavbar;
