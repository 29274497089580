import React from "react";

function FormField(props) {
  const { error, type, size, inputRef, tag, className, ...inputProps } = props;

  return (
    <div className="field">
      {props.label && (
        <label className="label" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <div className="control">

        {type === "textarea" && (
          <div>
            <textarea
              className={`textarea is-${size}`}
              ref={inputRef}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}

        
        {type === "text" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}
        {type === "password" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}
        {type === "email" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}
        {type === "number" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}
        {type === "tel" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}
        {type === "select" && (
          <div>
            <input
              className={`input is-${size}`}
              ref={inputRef}
              type={type}
              {...inputProps}
            />
            {error && <p className="help is-danger">{error.message}</p>}
          </div>
        )}



         {type === "settings" && (
          <div className={`settingsMb`}>
            <div className={`inputTag is-size-7`}>{tag}</div>
            <div>
              <input
                className={`input ${className} is-${size}`}
                ref={inputRef}
                type={`text`}
                {...inputProps}
              />
             {error && <p className="help is-danger">{error.message}</p>}
            </div>
          </div>
        )}




        {type === "settingsPassword" && (
          <div className={`settingsMb`}>
            <div className={`inputTag is-size-7`}>{tag}</div>
            <div>
              <input
                className={`input ${className} is-${size}`}
                ref={inputRef}
                type={`password`}
                {...inputProps}
              />
              {error && <p className="help is-danger">{error.message}</p>}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormField;
