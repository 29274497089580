import React, { useState, useRef } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

const ReferModal = ({ code }) => {
  const inputRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState(false);
  const handleFocus = (event) => {
    event.target.select();
    document.execCommand("copy");
    setCopyStatus(true);
  };

  const copyText = (event) => {
    inputRef.current.select();
    document.execCommand("copy");
    setCopyStatus(true);
  };

  const shareUrl = `https://www.tender.credit/?referralCode=${code}&channel=`;
  const title =
    "I just joined Tender to help me with extra cash at flexible rates. You should join too! #Tender";
  const twitterTitle =
    "I just joined @Tender to help me with extra cash at flexible rates. You should join too! #Tender";

  return (
    <div className="content has-text-centered">
      <span
        className="title is-size-1"
        role="img"
        aria-label="signup celebration"
      >
        🥳
      </span>
      <div className="column">
        <div
          className="field has-addons is-centered has-text-centered mt-4"
          style={{ justifyContent: "center" }}
        >
          <div className="control">
            <input
              className="input"
              type="text"
              defaultValue={shareUrl + "link"}
              value={shareUrl + "link"}
              readOnly
              onFocus={handleFocus}
              ref={inputRef}
            />
          </div>
          <div className="control">
            <button className="button is-info" onClick={copyText}>
              <i className={copyStatus ? "fas fa-check " : " far fa-copy"}></i>
            </button>
          </div>
        </div>

        {/* React share */}

        <div className="columns is-variable is-0">
          <div className="column is-one-fifth"></div>
          <div className="column">
            <FacebookShareButton
              url={shareUrl + "facebook"}
              quote={title}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className="column">
            <TwitterShareButton
              url={shareUrl + "twitter"}
              title={twitterTitle}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="column">
            <TelegramShareButton
              url={shareUrl + "telegram"}
              title={title}
              className="Demo__some-network__share-button"
            >
              <TelegramIcon size={32} round />
            </TelegramShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="column">
            <WhatsappShareButton
              url={shareUrl + "whatsapp"}
              title={title}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>
          <div className="column is-one-fifth"></div>
        </div>
      </div>
    </div>
  );
};

export default ReferModal;
