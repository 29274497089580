import React from "react";
import AppNavbar from "./AppNavbar";
import Section from "./Section";
import { useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";
import "./DashboardSection.scss";

function DashboardSection(props) {
  const auth = useAuth();
  const router = useRouter();
  const app = router.pathname.includes("/app");

  return (
    <Section color={props.color} size={props.size} className="p-0 pt-5 mr-0">
      {app && <AppNavbar />}
      {props.children}
    </Section>
  );
}

export default DashboardSection;
