import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import FundWalletModal from "../components/modals/FundWalletModal";
import WithdrawFromWalletModal from "../components/modals/WithdrawFromWalletModal";
import CustomTable from "../components/Table";
import { useAuth } from "../util/auth";
import { fundWallet, getUpdates } from "../util/user";
import {
  checkWalletBallance,
  withdrawByTransfer,
  previewTransactions,
  withdrawFromWallet,
  authenticateTransfer,
} from "../util/transactions";
import { columns } from "./dashboard.helper";
import { Link } from "../util/router";
import TransferFromWalletModal from "../components/modals/TransferFromWalletModal";
import OtpVerificationModal from "../components/modals/OtpVerificationModal";
import Toast from "../components/Toast";
import Loader from "../components/Loader";
import mixpanel from "mixpanel-browser";
const Dashboard = (props) => {
  const auth = useAuth();
  const user = auth.user;
  const [isLoading, setLoading] = useState(false);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [fundToggleOpen, setFundToggleOpen] = useState(false);
  const [transferToggleOpen, setTransferToggleOpen] = useState(false);
  const [otpToggleOpen, setOtpToggleOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [stats, setStats] = useState({});
  const [postTransferData, setPostTransferData] = useState({});
  const [disabled, setTransDisabled] = useState(true);
  const [withdrawDisabled, setWithdrawDisabled] = useState(true);

  let fundAmount;
  let transferData;
  let withdrawData;
  let otp;

  const openModal = (value) => {
    setToggleOpen(value);
  };

  const openFundModal = (value) => {
    setFundToggleOpen(value);
  };

  const openTransferModal = (value) => {
    setTransferToggleOpen(value);
  };

  const getData = (data) => {
    return (fundAmount = data);
  };

  const getOtp = (data) => {
    return (otp = data);
  };

  const getWithdrawAmount = (data) => {
    return (withdrawData = data);
  };

  const getTransferData = (data) => {
    return (transferData = data);
  };

  const fund = async () => {
    const data = {
      uid: user.uid,
      amount: fundAmount,
      access_token: user.accessToken,
    };
    setLoading(true);
    const result = await fundWallet(data);
    setLoading(false);
    window.open(result.link, "_blank");
    mixpanel.track('Fund wallet');
    setToggleOpen(false);
  };

  const withdrawMoney = async () => {
    setLoading(true);
    const data = {
      currency: "NGN",
      ...withdrawData,
    };
    if (parseInt(data.amount) <= 0) {
      setLoading(false);
      return Toast("Enter an amount greater than 0");
    } else if (parseInt(data.amount) > stats.walletBalance) {
      setLoading(false);
      return Toast("Insufficient balance to withdraw from.");
    } else {
      await withdrawFromWallet(data);
      mixpanel.track('New withdrawal');
      setLoading(false);
      setToggleOpen(false);
    }
  };

  const initiateTransfer = async () => {
    setLoading(true);
    const data = {
      currency: "NGN",
      ...transferData,
    };

    if (parseInt(data.amount) <= 0) {
      setLoading(false);
      return Toast("Enter an amount greater than 0");
    } else if (parseInt(data.amount) > stats.walletBalance) {
      setLoading(false);
      return Toast("Insufficient balance to withdraw from.");
    } else {
      const result = await withdrawByTransfer(data);
      setPostTransferData(result);
      mixpanel.track('Initiate transfer');
      setLoading(false);
      setTransferToggleOpen(false);
      setOtpToggleOpen(true);
    }
  };

  const verifyTransaction = async () => {
    setLoading(true);
    authenticateTransfer({
      pin: otp,
      pinId: postTransferData.pinId,
      txnRef: postTransferData.txnRef,
    })
      .then(() => {
        setLoading(false);
        Toast("Your funds will soon hit your account", "is-success");
      })
      .catch((error) => {
        Toast("We ran into an issue: " + error, "is-success");
      });
    setOtpToggleOpen(false);
  };

  useEffect(() => {
    const getTransactions = async () => {
      return previewTransactions({
        uid: user.uid,
        access_token: user.accessToken,
      });
    };

    const checkBallance = async () => {
      return checkWalletBallance({
        uid: user.uid,
        access_token: user.accessToken,
      });
    };

    if (user) {
      // check wallet balance
      checkBallance().then((result) => {
        setStats({
          totalBorrowings: user?.basicMetrics?.totalBorrowings,
          totalLendings: user?.basicMetrics?.totalLendings,
          totalBorrowingDecks: user?.basicMetrics?.totalBorrowingDecks,
          totalLendingDecks: user?.basicMetrics?.totalLendingDecks,
          totalIntEarned: user?.basicMetrics?.totalIntEarned,
          totalIntPaid: user?.basicMetrics?.totalIntPaid,
          walletBalance: result.balance,
        });
      });
      // get transactions
      getTransactions().then((result) => {
        setTransactions(result.transactions);
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchUpdates = async () => {
      const result = getUpdates();
      setUpdates(result.updates);
    };

    fetchUpdates();
  }, []);

  return (
    <>
      <Loader state={isLoading} />
      <div className="columns is-gapless Dashboard">
        <div className="column">
          <div className="columns">
            <div className="column">
              <div className="columns card card-radius Dashboard__overview is-shadowless is-pulled-right">
                <div className="column is-three-quarter">
                  {/* Card panel */}
                  <h1 className="title is-5 p-3 pl-3 pb-0 mb-0 is-primary has-text-weight-semibold primary-color">
                    Overview
                  </h1>
                  <button
                    className="button is-outlined ml-3 mt-0 has-text-weight-semibold"
                    onClick={() => openModal(true)}
                  >
                    Withdraw
                  </button>
                  <button
                    className="button is-outlined ml-1 mt-0 has-text-weight-semibold"
                    onClick={() => openTransferModal(true)}
                  >
                    Transfer
                  </button>
                </div>

                <div className="column Dashboard__activity">
                  {/* Activity Panel */}
                  <h1 className="title is-6 p-3 pl-3 pb-0 mb-0 mt-5 is-primary has-text-weight-semibold primary-color">
                    Activity
                  </h1>
                  <div>
                    <div className="columns m-3">
                      <div className="column is-four-fifths is-shadowless trans-bg ov-tabs">
                        <h1 className="title is-6 has-text-info">
                          Total Disbursements
                        </h1>
                        <p className="subtitle is-7 has-text-grey">
                          Interest earned - <span>{stats.totalIntEarned}</span>
                        </p>
                      </div>
                      <div className="column card is-shadowless is-vcentered activity-border has-text-weight-semibold move-center">
                        {stats.totalLendingDecks}
                      </div>
                    </div>

                    <div className="columns m-3">
                      <div className="column is-four-fifths is-shadowless trans-bg ov-tabs">
                        <h1 className="title is-6 has-text-primary">
                          Total Borrowings
                        </h1>
                        <p className="subtitle is-7 has-text-grey">
                          Interest paid - <span>{stats.totalIntPaid}</span>
                        </p>
                      </div>
                      <div className="column card is-shadowless activity-border has-text-weight-semibold move-center">
                        {stats.totalBorrowingDecks}
                      </div>
                    </div>

                    <div className="column mt-4">
                      <Link to="/app/activity">
                        <button className="button activity-button p-4 is-primary is-outlined is-fullwidth has-text-weight-semibold">
                          {" "}
                          View Active Decks
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
              </div>

              {/* Modal */}
              <Modal
                title="Verify this transaction"
                actionButton="Verify"
                action={verifyTransaction}
                toggle={otpToggleOpen}
                open={() => openModal(false)}
                loading={isLoading}
              >
                <OtpVerificationModal data={getOtp} />
              </Modal>

              <Modal
                title="Fund Wallet"
                actionButton="Fund"
                action={fund}
                toggle={fundToggleOpen}
                open={() => openFundModal(false)}
                loading={isLoading}
              >
                <FundWalletModal data={getData} />
              </Modal>

              <Modal
                title="Withdraw From Wallet"
                actionButton="Withdraw"
                action={withdrawMoney}
                toggle={toggleOpen}
                open={() => openModal(false)}
                loading={isLoading}
                buttonDisabled={withdrawDisabled}
              >
                <WithdrawFromWalletModal
                  data={getWithdrawAmount}
                  disabled={setWithdrawDisabled}
                />
              </Modal>

              <Modal
                title="Transfer From Wallet"
                actionButton="Transfer"
                action={initiateTransfer}
                toggle={transferToggleOpen}
                open={() => openTransferModal(false)}
                loading={isLoading}
                buttonDisabled={disabled}
              >
                <TransferFromWalletModal
                  data={getTransferData}
                  disabled={setTransDisabled}
                />
              </Modal>

              <div className="card Dashboard__debit-card is-shadowless is-pulled-left">
                {/* Debit Card */}
                <span className="title has-text-white is-6">
                  Wallet Balance
                </span>
                <div
                  className="card fund-button is-clickable"
                  onClick={() => openFundModal(true)}
                >
                  <span className="is-primary is-vcentered has-text-weight-semibold">
                    <i className="fas fa-plus-circle ml-1 mt-2"></i> Fund
                  </span>
                </div>
                <span className="title has-text-white is-3 mt-2">
                  <i className="mdi mdi-currency-ngn"></i>
                  {stats?.walletBalance}
                </span>
              </div>
            </div>
          </div>

          <div className="columns Dashboard__table">
            <div className="column">
              {/* Transaction Table */}
              <div className="has-text-weight-semibold is-size-4 has-text-primary">
                Transaction History
              </div>
              <CustomTable rows={transactions} columns={columns} />
            </div>
          </div>
        </div>

        {/* Updates Sidebar */}
        <div className="column Dashboard__updates is-one-third">
          <div className="columns ">
            <div className="column">
              <div className="card is-shadowless updates-card p-3">
                <div className="button no-border is-fullwidth move-center noCursor mb-3 pt-0">
                  {/* Updates title */}
                  <i className="mdi mdi-calendar-month-outline primary-color is-size-2 mr-2"></i>
                  <span className="is-size-6 has-text-weight-semibold primary-color">
                    UPDATES
                  </span>
                </div>

                <div className="updates-section">
                  {(updates?.length === 0 || !updates) && (
                    <div className="faded-color move-center is-size-5 pt-5">
                      No updates yet
                    </div>
                  )}

                  {updates &&
                    updates?.map((update) => (
                      <div
                        className="columns updates-card-holder"
                        key={update.description}
                      >
                        <div className="column is-one-quarter ">
                          <div className="updates-icon-holder">
                            <div className="is-shadowless">
                              <i className="fas fa-money-bill-wave is-secondary is-size-5"></i>
                            </div>
                          </div>
                        </div>
                        <div className="column is-three-quarters p-0 pt-3">
                          <span>{update.description}</span>
                          <p>{update.dueDate}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="card is-shadowless newsletter-card"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
