import React, { useEffect, useState } from "react";

const AddBankModal = (props) => {
  return (
    <form>
      <div className="control select is-fullwidth mb-3">
        <select className="select" name="bankAccount">
            <option>Select Bank</option>
        </select>
      </div>


      <input
        type="number"
        name="accountNumber"
        className="input mb-3"
        placeholder="Account Number"
      />

      <input
        type="number"
        name="bvn"
        className="input"
        placeholder="BVN"
      />

    </form>
  );
};

export default AddBankModal;
