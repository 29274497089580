import React from "react";
import "./../styles/global.scss";
import "./../styles/mdi/css/materialdesignicons.css";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import _App from "./app";
import AuthPage from "./auth";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import logo from "../assets/img/logo.png";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";
import { QueryClientProvider } from "./../util/db.js";
import ErrorBoundary from "../pages/error.js";

function App(props) {
  return (
    <ErrorBoundary>
      <QueryClientProvider>
        <AuthProvider>
          <Router>
            <>
              {/* <Navbar color="white" spaced={true} logo={logo} /> */}

              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/faq" component={FaqPage} />

                <Route exact path="/pricing" component={PricingPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route path="/app" component={_App} />

                <Route exact path="/auth/:type" component={AuthPage} />

                <Route component={NotFoundPage} />
              </Switch>

              {/* <Footer
                color="light"
                size="normal"
                backgroundImage=""
                backgroundImageOpacity={1}
                copyright="© 2021 Tender Credit"
                logo={logo}
              /> */}
            </>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
