import React, { useState, useEffect, useMemo } from "react";
import "../styles/marketplace.scss";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import MonoConnect from "@mono.co/connect.js";
import { linkMonoAccount } from "../util/transactions";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "../components/Modal";
import AuthModal from "../components/AuthModal";
import LoanPreviewModal from "../components/modals/LoanPreviewModal";
import { useAuth } from "../util/auth";
import { useRouter } from "../util/router";
import {
  filterDecks,
  previewDeck as pd,
  fetchBorrowingDecks,
  fetchLendingDecks,
  checkWalletBallance,
  fundDeck,
  initiateBorrowing,
  borrowDeck,
} from "../util/transactions";
import sendIcon from "../assets/img/send.png";
import receiveIcon from "../assets/img/receive.png";
import InfoModal from "../components/modals/InfoModal";
import Loader from "../components/Loader";
import Toast from "../components/Toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getProfile } from "../util/user";
import mixpanel from "mixpanel-browser";

const Marketplace = () => {
  const auth = useAuth();
  const router = useRouter();
  const user = auth.user;
  const [activeTab, setActiveTab] = useState("borrow");
  const [filterAmount, setFilterAmount] = useState([1000, 30000]);
  const [filterInterest, setFilterInterest] = useState([1, 20]);
  const [filterTenor, setFilterTenor] = useState([1, 50]);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [successToggleOpen, setSuccessToggleOpen] = useState(false);
  const [reviewToggleOpen, setReviewToggleOpen] = useState(false);
  const [toggleAuthOpen, setToggleAuthOpen] = useState(false);
  const [walletBallance, setWalletBalance] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleSwitch = (value) => {
    setActiveTab(value);
  };

  function valuetext(value) {
    return `N${value}`;
  }

  const handleChange = (event, newValue, type) => {
    switch (type) {
      case "filterAmount":
        setFilterAmount(newValue);
        break;
      case "filterInterest":
        setFilterInterest(newValue);
        break;
      case "filterTenor":
        setFilterTenor(newValue);
        break;

      default:
        break;
    }
  };

  const openModal = (value) => {
    setToggleOpen(value);
  };

  const openReviewModal = () => {
    setToggleOpen(false);
    setReviewToggleOpen(true);
  };

  const openSuccessModal = (value) => {
    setReviewToggleOpen(false);
    setSuccessToggleOpen(value);
  };

  const openAuthModal = (value) => {
    setToggleAuthOpen(value);
  };

  const previewDeck = async (deckId) => {
    setLoading(true);
    const data = await pd({
      deckId,
    });
    setPreviewData(data);
    setLoading(false);
  };

  const handleFilter = async () => {
    setLoading(true);
    const data = {
      type: activeTab + "ing",
      maxAmount: filterAmount[1],
      minAmount: filterAmount[0],
      maxInterestRate: filterInterest[1],
      minInterestRate: filterInterest[0],
      maxTenor: filterTenor[1],
      minTenor: filterTenor[0],
      tenorUnit: "days",
    };
    const filteredDecks = await filterDecks(data);
    setTableData(filteredDecks.decks);
    setLoading(false);
  };

  const fundDeckRequest = async (password) => {
    if (activeTab === "lend") {
      let data;
      if (previewData) {
        setLoading(true);
        data = {
          currency: "NGN",
          tenorUnit: "days",
          ...previewData,
        };
        // add password to the payload
        setPreviewData({ password, ...previewData });
        openAuthModal(false);
        openModal(false);
        // make request
        const response = await fundDeck(data);
        if (response.status === 200) {
          // open success modal
          openSuccessModal(true);
          mixpanel.track('Fund lending deck');
        } else {
          Toast("Could not fund deck, please try again", "is-danger");
        }
        // stop loading
        setLoading(false);
      }
    }
  };

  const borrowDeckRequest = async () => {
    setLoading(true);
    if (activeTab === "borrow") {
      // initiate borrowing
      const authority = await initiateBorrowing({ deckId: previewData.deckId });
      if (authority.status === 200) {
        setLoading(false);
        // open mono widget
        window.open(authority.paymentLink, "_self");
        mixpanel.track('Borrow deck request');
      } else {
        Toast(
          "We could not complete your request, please try again",
          "is-danger"
        );
      }
    }
  };

  useEffect(() => {
    const checkBalance = async () => {
      const balance = await checkWalletBallance();
      setWalletBalance(balance.balance);
    };

    checkBalance();
    const getLendingDecks = async () => {
      return await fetchLendingDecks();
    };

    const getBorrowingDecks = async () => {
      return await fetchBorrowingDecks({});
    };

    if (activeTab === "borrow") {
      // transform the data based on the status of deck choosen
      setLoading(true);
      getBorrowingDecks().then((result) => setTableData(result.decks));
      setLoading(false);
    } else if (activeTab === "lend") {
      setLoading(true);
      getLendingDecks().then((result) => setTableData(result.decks));
      setLoading(false);
    }
  }, [activeTab, user]);

  useEffect(() => {
    const reference = router.query.tenderToken;
    const status = router.query.status;
    if (reference) {
      setLoading(true);
      let retryCount = 0;
      // I should probably show a loading screen while verifying
      // call to verify payment
      const verify = async () => {
        if (retryCount > 2 && status === "failed") {
          // show error toast and allow user to try again
          Toast(
            "Kindly complete your direct debit activation to complete borrowing",
            "is-danger"
          );
          return;
        }

        if (retryCount > 2) {
          // return if there is an unexpected error from the server
          // show error toast and indicate that the problem is from the server
          Toast(
            "Something went wrong on our side, kindly contact customer support",
            "is-danger"
          );
          return;
        }

        borrowDeck({ token: reference }).then((response) => {
          if (response) {
            // notify server
            Toast("Successfully borrowed this deck", "is-success");
            return router.push(`/app/marketplace`);
          } else {
            console.error(
              `Something went wrong while verifying payment: ${response}`
            );
            console.info("retrying in 3, 2, 1...");
            setTimeout(() => {
              verify();
              retryCount = retryCount + 1;
            }, 3000);
          }
        });
      };
      setLoading(false);
      verify();
    }
  }, [router]);

  const previewMessage = (
    <div className="px-5 py-3">
      <div className="subtitle has-text-weight-semibold py-5 primary-color move-center">
        {`The sum of ₦${previewData?.amount} will be ${
          activeTab === "borrow"
            ? "credited into your wallet"
            : "deducted from your wallet"
        }. You will ${
          activeTab === "borrow" ? "be expected to repay" : "receive"
        } ${
          activeTab === "borrow"
            ? `₦${previewData?.paymentsDue}`
            : `₦${previewData?.lendersDue}`
        } on ${previewData?.dueDate?.dueDate?.split("T")[0]}`}
      </div>
    </div>
  );

  const handleDisable = () => {
    setDisabled(!disabled);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 300 + theme.spacing(3) * 2,
    },
    margin: {
      height: theme.spacing(3),
    },
  }));
  function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => true,
      onLoad: () => true,
      onSuccess: async ({ code }) => {
        await linkMonoAccount({
          access_token: user.access_token,
          uid: user.uid,
          code,
        })
          .then(async () => {
            await getProfile();
            Toast("Linked account successfully!", "is-success");
          })
          .catch(() =>
            Toast(
              "Trouble linking your account, please try again!",
              "is-danger"
            )
          );
      },
      key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    });
    monoInstance.setup();
    return monoInstance;
  }, [user]);

  ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
  };
  const AngelSlider = withStyles({
    root: {
      color: "#091F5C",
      height: 2,
      padding: "15px 0",
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      marginTop: -12,
      marginLeft: -14,
      border: "2px solid #b5b5b5",
      "&:focus, &:hover, &$active": {},
    },
    valueLabel: {
      left: "calc(-50% + 2px)",
      top: -22,
      "& *": {
        background: "transparent",
        color: "#b5b5b5",
        fontSize: "small",
      },
    },
    track: {
      height: 3,
    },
    rail: {
      height: 3,
      backgroundColor: "#b5b5b5",
    },
  })(Slider);

  return (
    <>
      <Loader state={isLoading} />
      <div className="columns Marketplace">
        <div className="column is-two-thirds">
          {/* Tabs */}
          <div className="tabs Marketplace__tabs">
            <ul>
              <li className={`${activeTab === "borrow" ? "is-active" : ""}`}>
                <a
                  onClick={() => handleSwitch("borrow")}
                  className="has-text-weight-semibold is-size-5"
                >
                  Borrow
                </a>
              </li>
              <li className={activeTab === "lend" ? "is-active" : ""}>
                <a
                  onClick={() => handleSwitch("lend")}
                  className="has-text-weight-semibold is-size-5 ml-4"
                >
                  Lend
                </a>
              </li>
            </ul>
          </div>
          <div className="liner-color"></div>
          {/* Modal here for now */}
          <Modal
            toggle={toggleOpen}
            open={() => openModal(false)}
            action={openReviewModal}
            title="Loan Preview"
            actionButton="Confirm"
            buttonDisabled={disabled}
            loading={isLoading}
          >
            <LoanPreviewModal
              data={{ type: activeTab, ...previewData }}
              checked={handleDisable}
            />
          </Modal>

          {/* Review message */}
          <Modal
            toggle={reviewToggleOpen}
            open={() => openSuccessModal(false)}
            title={activeTab === "borrow" ? "Borrow" : "Lend"}
            actionButton="Confirm"
            loading={isLoading}
            action={
              activeTab === "borrow"
                ? borrowDeckRequest
                : () => openAuthModal(true)
            }
          >
            <InfoModal info={previewMessage} noCheckMark />
          </Modal>

          {/* Success message */}
          <Modal
            toggle={successToggleOpen}
            noFooter
            open={() => openSuccessModal(false)}
            loading={isLoading}
          >
            <InfoModal info="Successful!" />
          </Modal>

          {/* Authentication Modal */}
          <AuthModal
            actionButton="Authenticate"
            toggle={toggleAuthOpen}
            open={() => openAuthModal(false)}
            data={fundDeckRequest}
            loading={isLoading}
          />

          {tableData && (
            <div className="columns">
              <div className=" column is-11">
                <div className="columns is-centered Marketplace__table titleb">
                  <div className="column is-3"></div>
                  <div className="column is-3">Tenor</div>
                  <div className="column is-3">Interest Rate</div>
                  <div className="column is-3">Amount</div>
                </div>
              </div>
            </div>
          )}

          {/* Table */}
          {
            tableData?.map((item) => (
              <div className="columns">
                <div className="column is-11">
                  <div
                    className="columns is-vcentered Marketplace__table card is-shadowless"
                    onClick={() => {
                      previewDeck(item.deckId);
                      return openModal(true);
                    }}
                    key={item.deckId}
                  >
                    <div className="column has-text-weight-semibold">
                      <img
                        src={activeTab === "borrow" ? receiveIcon : sendIcon}
                      />
                    </div>

                    <div className="column has-text-weight-semibold">
                      {item.tenor} {item.tenorUnit}
                    </div>
                    <div className="column has-text-weight-semibold">
                      {item.interestRate}%
                    </div>
                    <div className="column has-text-weight-semibold">
                      <i className="mdi mdi-currency-ngn"></i>
                      {item.amount}
                    </div>
                  </div>
                </div>
                <div className="column dropdown is-up is-hoverable">
                  <div class="dropdown-trigger">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                    >
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <div className="dropdown-item">
                        <CopyToClipboard
                          text={item.deckId}
                          onCopy={() => Toast("Copied!")}
                        >
                          <button class="button">
                            <span>
                              <p>Copy Deck ID</p>
                            </span>
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <div className="column is-one-third Marketplace__filter mt-5">
          <div className="card is-shadowless pl-3 pr-2 pt-3 pb-4 balance_holder has-text-weight-semibold">
            Wallet Balance
            <div className="card is-shadowless is-pulled-right pt-1 pb-1 pl-3 pr-3 has-text-weight-semibold">
              <i className="mdi mdi-currency-ngn"></i>
              {walletBallance}
            </div>
          </div>

          <div className="column has-text-primary has-text-centered mt-3 primary-color has-text-weight-bold">
            FILTER
          </div>
          <div>
            <div className="slider-style button mt-4">
              <span className="mr-5 sec-color has-text-weight-semibold">
                Amount
              </span>
              <AngelSlider
                value={filterAmount}
                onChange={(event, value) =>
                  handleChange(event, value, "filterAmount")
                }
                label="filterAmount"
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                min={parseInt(process.env.REACT_APP_MIN_FILTER_AMOUNT)}
                max={
                  activeTab !== "lend"
                    ? parseInt(user?.creditLimit)
                    : parseInt(process.env.REACT_APP_MAX_FILTER_AMOUNT)
                }
                className="slider-style-thumb"
              />
            </div>
            <div className="slider-style button mt-4">
              <span className="mr-5 sec-color has-text-weight-semibold">
                Interest
              </span>
              <AngelSlider
                value={filterInterest}
                onChange={(event, value) =>
                  handleChange(event, value, "filterInterest")
                }
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                min={parseInt(process.env.REACT_APP_MIN_FILTER_INTEREST)}
                max={parseInt(process.env.REACT_APP_MAX_FILTER_INTEREST)}
                className="slider-style-thumb"
              />
            </div>
            <div className="slider-style button mt-4">
              <span className="mr-5 sec-color has-text-weight-semibold">
                Tenor
              </span>
              <AngelSlider
                value={filterTenor}
                onChange={(event, value) =>
                  handleChange(event, value, "filterTenor")
                }
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                getAriaValueText={valuetext}
                min={parseInt(process.env.REACT_APP_MIN_FILTER_TENOR)}
                max={parseInt(process.env.REACT_APP_MAX_FILTER_TENOR)}
                className="slider-style-thumb"
              />
            </div>
            <div className="column has-text-centered mt-5 p-0">
              <button
                className="button is-primary p-4 has-text-weight-semibold"
                style={{ width: "100%" }}
                onClick={handleFilter}
              >
                FILTER
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
