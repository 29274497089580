import Dashboard from "../pages/dashboard";
import MarketPlace from "../pages/marketplace";
import CreateDeck from "../pages/createdeck";
import Settings from "../pages/settings";
import Activity from "../pages/activity";
// const Dashboard = React.lazy(() => import("../pages/dashboard"));
// const MarketPlace = React.lazy(() => import("../pages/marketplace"));
// const CreateDeck = React.lazy(() => import("../pages/createdeck"));
// const Settings = React.lazy(() => import("../pages/settings"));
// const Activity = React.lazy(() => import("../pages/activity"));

const routes = [
  {
    icon: "mdi mdi-view-dashboard-outline",
    title: "Dashboard",
    activeLink: "/app/dashboard",
    isActive: true,
    component: Dashboard,
  },
  {
    activeLink: "/app/marketplace/:tenderToken",
    isActive: true,
    component: MarketPlace,
  },
  {
    icon: "mdi mdi-cart",
    title: "Marketplace",
    activeLink: "/app/marketplace",
    isActive: true,
    component: MarketPlace,
  },
  {
    activeLink: "/app/create-deck/:tenderToken",
    isActive: true,
    component: CreateDeck,
  },
  {
    icon: "mdi mdi-cloud-upload-outline",
    title: "Create Deck",
    activeLink: "/app/create-deck",
    isActive: true,
    component: CreateDeck,
  },
  {
    activeLink: "/app/activity/:tenderToken",
    isActive: true,
    component: Activity,
  },
  {
    icon: "mdi mdi-clock-time-three-outline",
    title: "Activity",
    activeLink: "/app/activity",
    isActive: true,
    component: Activity,
  },
  {
    icon: "mdi mdi-cog-outline",
    title: "Settings",
    activeLink: "/app/settings",
    isActive: true,
    component: Settings,
  },
];

export default routes;
