import React, { useEffect, useState } from "react";
import "./SettingsSection.scss";
import ReauthModal from "./ReauthModal";
import SettingsNav from "./SettingsNav";
import FormAlert from "./FormAlert";
import SettingsProfile from "./SettingsProfile";
import SettingsPassword from "./SettingsPassword";
import { useAuth } from "./../util/auth.js";
import SettingsBank from "./SettingsBank";
import { useRouter } from "../util/router";

function SettingsSection(props) {
  const auth = useAuth();
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  // State to control whether we show a re-authentication flow
  // Required by some security sensitive actions, such as changing password.
  const [reauthState, setReauthState] = useState({
    show: false,
  });

  const validSections = {
    profile: true,
    password: true,
    bank: true,
  };

  const section = validSections[props.section] ? props.section : "general";

  // Handle status of type "success", "error", or "requires-recent-login"
  // We don't treat "requires-recent-login" as an error as we handle it
  // gracefully by taking the user through a re-authentication flow.
  const handleStatus = ({ type, message, callback }) => {
    if (type === "requires-recent-login") {
      // First clear any existing message
      setFormAlert(null);
      // Then update state to show re-authentication modal
      setReauthState({
        show: true,
        // Failed action to try again after reauth
        callback: callback,
      });
    } else {
      // Display message to user (type is success or error)
      setFormAlert({
        type: type,
        message: message,
      });
    }
  };

  useEffect(() => {
    if (router.pathname === "/app/settings" && router.location.search === "")
      router.push("/app/settings?section=profile");
  });

  return (
    <div>
      {reauthState.show && (
        <ReauthModal
          callback={reauthState.callback}
          provider={auth.user.providers[0]}
          onDone={() => setReauthState({ show: false })}
        />
      )}

      <SettingsNav activeKey={section} parentColor={props.color} />
      <div className="SettingsSection__container container">
        {formAlert && (
          <FormAlert
            type={formAlert.type}
            message={formAlert.message}
            style={{ maxWidth: "450px" }}
          />
        )}

        {section === "profile" && (
          <SettingsProfile
            buttonColor={props.buttonColor}
            buttonInverted={props.buttonInverted}
            onStatus={handleStatus}
          />
        )}

        {section === "bank" && (
          <SettingsBank
            buttonColor={props.buttonColor}
            buttonInverted={props.buttonInverted}
            onStatus={handleStatus}
          />
        )}

        {section === "password" && (
          <SettingsPassword
            buttonColor={props.buttonColor}
            buttonInverted={props.buttonInverted}
          />
        )}
      </div>
    </div>
  );
}

export default SettingsSection;
