import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { fetchBankAccounts, fetchTransferFee } from "../../util/transactions";

const WithdrawFromWalletModal = (props) => {
  const { register, control } = useForm();
  const [bankAccounts, setBankAccounts] = useState(null);
  const [fee, setFee] = useState(0);
  const [accountName, setAccountName] = useState();
  const [bankName, setBankName] = useState();
  const [insufficientText, setInsufficientText] = useState("");
  const data = useWatch({
    control,
    name: ["amount", "bankAccount", "password"], // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  const bankAccountDetails = () =>
    bankAccounts?.find(
      (account) => account.accountNumber === data?.bankAccount
    );
  props.data({ ...bankAccountDetails(), ...data });

  const getDetails = () => {
    const getAccountDetails = () => {
      const account = bankAccounts?.find(
        (account) => account.accountNumber === data?.bankAccount
      );
      setBankName(account.bankName);
      setAccountName(account.accountName);
    };
    getAccountDetails();
  };

  const handleFees = async () => {
    const getTransferFee = async () => {
      const fee = await fetchTransferFee({ amount: data?.amount });
      setFee(fee.transferFee);
      if (fee.sufficientBalance === true) {
        props.disabled(false);
        setInsufficientText("");
      } else {
        props.disabled(true);
        setInsufficientText("Insufficient balance to complete this request");
      }
    };

    if (data?.amount?.length > 1) {
      getTransferFee();
    }
  };

  // fetch bank accounts
  useEffect(() => {
    let mounted = true;
    const getBankAccounts = async () => {
      mounted = true;
      const accounts = await fetchBankAccounts();
      setBankAccounts(accounts?.linkedAccounts);
    };

    if (bankAccounts === null) {
      getBankAccounts();
    }

    return () => (mounted = false);
  }, [bankAccounts]);

  return (
    <form>
      <label className="label">Account</label>
      <div className="control select is-fullwidth mb-3">
        <select
          className="select"
          name="bankAccount"
          ref={register()}
          onFocus={getDetails}
        >
          {bankAccounts?.map((account, index) => (
            <option key={index + "s"}>{account.accountNumber}</option>
          ))}
        </select>
      </div>

      <label className="label">Bank Name</label>
      <input
        className="input"
        readOnly={true}
        disabled={true}
        value={bankName}
      />

      <label className="label">Account Name</label>
      <input
        className="input"
        readOnly={true}
        disabled={true}
        value={accountName}
      />

      <label className="label">Amount</label>
      <input
        type="number"
        name="amount"
        ref={register()}
        className="input mb-3"
        defaultValue={0}
      />

      <label className="label is-size-7 mb-3">{`You will be charged N${parseInt(
        fee
      )} in transaction fees.`}</label>

      <label className="label">Total Charge</label>
      <input
        className="input"
        readOnly={true}
        disabled={true}
        value={`N ${parseFloat(fee) + parseFloat(data?.amount)}`}
      />

      <label className="label">Password</label>
      <input
        onFocus={handleFees}
        type="password"
        name="password"
        ref={register()}
        className="input"
      />
      <br />
      {insufficientText != "" && (
        <div>
          <br />
          <label className="is-size-6" style={{ color: "red" }}>
            {insufficientText}
          </label>
        </div>
      )}
    </form>
  );
};

export default WithdrawFromWalletModal;
