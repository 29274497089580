import React from "react";
import "./CreateDeckPreviewModal.scss";

const CreateDeckPreviewModal = (props) => {
  const { preLoadedDecks } = props;
  return (
    <div className="p-3 has-text-primary CreateDeck">
      <div>
        <span>Below is a list of similar decks already on the marketplace</span>
      </div>
      {preLoadedDecks?.map((deck) => {
        return (
          <div
            className="columns CreateDeck__table card is-shadowless"
            key={deck.deckId}
          >
            <div className="column">{deck.deckId}</div>
            <div className="column">{`${deck.tenor} ${deck.tenorUnit}`}</div>
            <div className="column">{deck.interestRate}%</div>
            <div className="column">{deck.amount}</div>
            <div className="column has-text-right">
              <i className="fas fa-sign-in-alt"></i>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CreateDeckPreviewModal;
