import React, { useState } from "react";
import "./Footer.css";
import FormModal from "../FormModal";
import newsletter from "../../util/newsletter";
import mixpanel from "mixpanel-browser";

import logo from "../../assets/img/logofooter.png";
import twitter from "../../assets/img/twitter.png";
import facebook from "../../assets/img/facebook.png";
import instagram from "../../assets/img/instagram.png";

export default function Footer() {
  const [open, setOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const handleSubmit = async (data) => {
    setSubscribed(true);
    await newsletter.subscribe(data);
    // await joinWaitlist(data);
  };
  return ( 
    <>
      <FormModal
                open={() => setOpen(false)}
                toggle={open}
                getData={handleSubmit}
                isSubmitted={subscribed}
              />
      <div>
        <div className="footer__header">
          <h5 style={{ color: "#62666C", }}>Join other users on Tender today!</h5>
          <br />
          <button  className="footer__button" onClick={() => {
                    setOpen(true);
                    mixpanel.track('Request early access');
                  }}>Get started</button>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="footer__logoBox">
          <img src={logo} alt="Tender" className="footer__logo" />
          <br/>
          <br/>
          <p>© 2021 Tender Credit</p>
        </div>
        <br/>
        <div className="footer__handles">
          <div className="div">
            <img src={twitter} alt="twitter" className="footer__handle" />
            <img src={facebook} alt="facebook" className="footer__handle" />
            <img src={instagram} alt="instagram" className="footer__handle" />
          </div>
        </div>
      </div>
    </>
  );
}
