import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";

const OtpVerificationModal = (props) => {
  const { register, control, errors } = useForm();
  const data = useWatch({
    control,
    name: "otp", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  props.data(data);

  return (
    <form>
      <label className="label">OTP</label>
      <input type="number" name="otp" ref={register()} className="input" />
    </form>
  );
};

export default OtpVerificationModal;
