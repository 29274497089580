import React from "react";
import Card from "./Card";
import "./HowItWorks.css";
import matched from "../../assets/img/matched.png";
import create from "../../assets/img/create.png";
import exchange from "../../assets/img/exchange.png";
import offer from "../../assets/img/offer.png";

export default function HowItWorks() {
  return (
    <div className="howItWorks">
      <h2 style={{ fontFamily: 'Mulish', fontWeight: "bold", fontSize: 24, color: '#001E5A' }}>How it works</h2>
      <br />
      <br />
      <div className="howItWorks__cards">
        <Card
          title="Create an account"
          image={create}
          pararaph="Provide and verify your basic details."
        />

        <Card
          title="Make an offer or request"
          image={offer}
          pararaph=" Let us know how much you want to borrow or lend out and your terms."
        />

        <Card
          title="Get matched"
          image={matched}
          pararaph="An interested and verified borrower or lender accepts your terms."
        />

        <Card
          title="Exchange value"
          image={exchange}
          pararaph="The lender’s money is received by the borrower."
        />
      </div>
    </div>
  );
}
