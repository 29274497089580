import { apiRequest } from "./util";

function linkMonoAccount(data) {
  return apiRequest(`linked-account/link`, "POST", data);
}

function checkWalletBallance() {
  return apiRequest(`wallet/checkbalance`, "GET", null);
}

function previewTransactions() {
  return apiRequest(`transaction/preview`, "GET", null);
}

function fetchTransactions(data) {
  return apiRequest(`transaction/fetch`, "GET", null);
}

function fetchActiveDecks(data) {
  return apiRequest(`deck/activity/active`, "GET", data);
}

function fetchUnmatchedDecks(data) {
  return apiRequest(`deck/activity/unmatched`, "GET", data);
}

function fetchClosedDecks(data) {
  return apiRequest(`deck/activity/closed`, "GET", data);
}

function fetchBorrowingDecks() {
  return apiRequest(`deck/borrowings/fetch`, "GET", null);
}

function fetchLendingDecks() {
  return apiRequest(`deck/lendings/fetch`, "GET", null);
}

function previewDeck(data) {
  return apiRequest(`deck/preview/${data.deckId}`, "GET", null);
}

function preloadDecks(data) {
  return apiRequest(`deck/precreate/search`, "POST", data);
}

function filterDecks(data) {
  return apiRequest(`deck/marketplace/filter`, "POST", data);
}

function fundWallet(data) {
  return apiRequest("wallet/fund/generate", "POST", data);
}

function fundDeck(data) {
  return apiRequest(`deck/fund`, "POST", data);
}

function initiateBorrowing(data) {
  return apiRequest(`deck/borrow/initiate`, "POST", data);
}

function borrowDeck(data) {
  return apiRequest(`deck/borrow`, "POST", data);
}

function uploadLendingDeck(data) {
  return apiRequest(`deck/create/lending`, "POST", data);
}

function uploadBasicBorrowingDeck(data) {
  return apiRequest(`deck/create/basic/borrowing`, "POST", data);
}

function initiateCreateBorrowing(data) {
  return apiRequest(`deck/initiate/borrowing/create`, "POST", data);
}

function authorizeDirectDebit(data) {
  return apiRequest(`deck/directdebit/authorize`, "POST", data);
}

function authorizeDirectDebitActivity(data) {
  return apiRequest(`deck/directdebit/authorize/activity`, "POST", data);
}

function confirmDirectDebit(data) {
  return apiRequest(`deck/directdebit/confirm`, "POST", data);
}

function repayDeck(data) {
  return apiRequest(`deck/repay/wallet`, "POST", data);
}

function editDeck(data) {
  return apiRequest(`deck/edit`, "POST", data);
}
function cancelDeck(data) {
  return apiRequest(`deck/cancel`, "POST", data);
}

function fetchBankAccounts() {
  return apiRequest(`wallet/withdrawal/accounts/fetch`, "GET", null);
}

function fetchBanks() {
  return apiRequest(`wallet/fetchbanks/NG`, "GET", null);
}

function fetchTransferFee(data) {
  return apiRequest(`wallet/transfer/resolve/fee`, "POST", data);
}

function resolveBankAccount(data) {
  return apiRequest(`wallet/resolve/account`, "POST", data);
}

function withdrawFromWallet(data) {
  return apiRequest(`wallet/withdrawal/initiate`, "POST", data);
}

function withdrawByTransfer(data) {
  return apiRequest(`wallet/transfer/initiate`, "POST", data);
}

function authenticateTransfer(data) {
  return apiRequest(`wallet/transfer/authenticate`, "POST", data);
}

export {
  linkMonoAccount,
  checkWalletBallance,
  previewTransactions,
  fundWallet,
  fundDeck,
  borrowDeck,
  previewDeck,
  filterDecks,
  fetchBorrowingDecks,
  fetchLendingDecks,
  fetchActiveDecks,
  fetchUnmatchedDecks,
  fetchClosedDecks,
  fetchTransactions,
  fetchBankAccounts,
  uploadLendingDeck,
  uploadBasicBorrowingDeck,
  initiateCreateBorrowing,
  preloadDecks,
  repayDeck,
  editDeck,
  cancelDeck,
  withdrawFromWallet,
  authorizeDirectDebit,
  confirmDirectDebit,
  initiateBorrowing,
  fetchBanks,
  resolveBankAccount,
  fetchTransferFee,
  withdrawByTransfer,
  authenticateTransfer,
  authorizeDirectDebitActivity,
};
