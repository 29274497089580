import React, { useState, useEffect } from "react";
import { customAlphabet } from "nanoid/non-secure";
import "./FormModal.scss";
import ReferModal from "./ReferModal";
import { useForm, useWatch } from "react-hook-form";
import { useRouter } from "./../util/router.js";

const FormModal = ({ open, toggle, isSubmitted, getData }) => {
  const router = useRouter();
  const { register, control, handleSubmit, errors } = useForm();
  const [submitted, hasSubmitted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [referralCode, setReferralCode] = useState();
  const [referredBy, setReferredBy] = useState("");
  const [referralChannel, setReferralChannel] = useState("");

  const data = useWatch({
    control,
    name: "channel", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  const onSubmit = (data) => {
    // generate referral code
    const alphanumerics = "abcdefghijklmnopqrstuvwxyz1234567890" + data.name;
    const code = customAlphabet(alphanumerics, 5);
    const referr = code().replace(/ /g, ""); // Do this to ensure the same code is sent to db and referr component. Also remove all white spaces within the code to avoid any issues
    setReferralCode(referr);
    getData({ referralCode: referr, referredBy, referralChannel, ...data });
    hasSubmitted(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    hasSubmitted(false);
    open(false);
  };

  useEffect(() => {
    if (data === "Others") {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [data]);

  useEffect(() => {
    setIsOpen(toggle);
  }, [toggle]);

  useState(() => {
    hasSubmitted(isSubmitted);
  }, [isSubmitted]);

  // check for a referralCode
  useEffect(() => {
    setReferredBy(
      router.query.referralCode === undefined ? "" : router.query.referralCode
    );
    setReferralChannel(
      router.query.channel === undefined ? "" : router.query.channel
    );
  }, [router.query.referralCode, router.query.channel]);
  return (
    <div className={`FormModal modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={handleModalClose}></div>
      <div className="modal-content" style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
        <div className="modal-card">
          <div className="modal-card-body">
            <div className="modal-card-title has-text-centered has-text-primary mb-4 mt-4">
              {submitted ? "Now invite your friends!" : "Get Early Access"}
            </div>
            {submitted && <ReferModal code={referralCode} />}
            {!submitted && (
              <div className="content">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <p className="control">
                      {errors.name?.type === "required" && (
                        <p className="has-text-danger is-danger is-7 subtitle">
                          Please tell us your name
                        </p>
                      )}
                      <input
                        type="text"
                        className="input"
                        placeholder="*Your name"
                        name="name"
                        ref={register({ required: true })}
                      />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      {errors.email?.type === "required" && (
                        <p className="has-text-danger is-danger is-7 subtitle">
                          Please fill in your email
                        </p>
                      )}

                      <input
                        type="email"
                        className="input"
                        placeholder="*Your email"
                        name="email"
                        ref={register({
                          required: true,
                          pattern:
                            // eslint-disable-next-line
                            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        })}
                      />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control">
                      <input
                        type="tel"
                        className="input"
                        placeholder="Your phone number"
                        name="phone"
                        ref={register({ required: false })}
                      />
                    </p>
                  </div>
                  <div className="field pt-3">
                    <div className="label has-text-weight-normal  has-text-secondary">
                      Where did you hear about us?
                    </div>
                    <div className="control select is-fullwidth">
                      <select
                        className=" has-text-gray"
                        name="channel"
                        ref={register({ required: false })}
                      >
                        <option defaultValue="Twitter">Twitter</option>
                        <option>Facebook</option>
                        <option>Instagram</option>
                        <option>A Friend</option>
                        <option>Others</option>
                      </select>
                    </div>
                    {showMore && (
                      <div className="field">
                        <div className="control mt-3">
                          <input
                            type="text"
                            className="input"
                            placeholder="Tell us more"
                            name="more"
                            ref={register({ required: false })}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="field is-grouped pt-3">
                    <input
                      className="button is-primary is-fullwidth"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            )}
            {/* {!submitted && (
              <p className="subtitle is-7 is-fullwidth has-text-centered">
                *We promise not to spam you
              </p>
            )} */}
          </div>
        </div>
      </div>
      <button
        className="modal-close"
        aria-label="close"
        onClick={handleModalClose}
      ></button>
    </div>
  );
};

export default FormModal;
