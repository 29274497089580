import React, { useState, useEffect, useMemo } from "react";
import Tabs from "../components/Tabs";
import Table from "../components/TableActivity";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import { useAuth } from "../util/auth";
import { columns, rows } from "./activity.helper";
import {
  fetchActiveDecks,
  fetchUnmatchedDecks,
  fetchClosedDecks,
  repayDeck,
  editDeck,
  cancelDeck,
  authorizeDirectDebitActivity,
  confirmDirectDebit,
} from "../util/transactions";
import "../styles/activity.scss";
import { useRouter } from "../util/router";
import { checkPhone } from "../util/user";
import Toast from "../components/Toast";
import mixpanel from "mixpanel-browser";
const EditDeckModal = React.lazy(() =>
  import("../components/modals/EditDeckModal")
);

const Activity = () => {
  const auth = useAuth();
  const router = useRouter();
  const user = auth.user;
  const [activeTab, setActiveTab] = useState("");
  const [activeSwitch, setActiveSwitch] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [rowData, setRowData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [tableActions, setTableActions] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [reset, setReset] = useState(false);
  const tabs = [
    { name: "Closed decks" },
    { name: "Active decks" },
    { name: "Unmatched decks" },
  ];
  const type = activeSwitch ? "borrowing" : "lending";
  let getter = null;
  let resetToggle = false;
  const openModal = (value) => {
    setToggleOpen(value);
  };

  const getData = (data) => {
    return (getter = data);
  };

  const handleEditDeck = async () => {
    setLoading(true);
    const edits = getter;
    const result = await editDeck({ deckId: rowData.deckId, ...edits });
    if (result) {
      Toast("Successfully edited deck", "is-success");
      setEdited(!edited);
      setToggleOpen(false);
      setReset(true);
    } else {
      Toast("Could not edit deck, please try again", "is-danger");
    }
    setLoading(false);
    setReset(false);
  };

  const handleCancelDeck = async (deckId) => {
    setLoading(true);
    console.log(deckId);
    const response = await cancelDeck({ deckId });
    if (response) {
      Toast("Deck has been cancelled successfully", "is-success");
      mixpanel.track('Deck cancellation');
    } else {
      Toast("Could not cancel deck. Try again please", "is-danger");
    }
    setEdited(!edited);
    setLoading(false);
  };

  const handleAuthorize = async (rowData) => {
    setLoading(true);
    const authority = await authorizeDirectDebitActivity({
      deckId: rowData.deckId,
    });
    if (authority) {
      Toast("Opening direct debit...", "is-success");
    } else {
      Toast("Could not authorized deck, please try again", "is-danger");
    }
    setLoading(false);
    // open mono widget
    window.open(authority.paymentLink, "_self");
  };

  const handleSwitch = (state) => {
    setActiveSwitch(state);
  };

  const handleToggle = (state) => {
    setActiveTab(state);
  };

  const actions = (state) => {
    switch (state) {
      case "active_borrowing":
        return [
          {
            icon: () => (
              <button className="button primary-button has-text-weight-semibold activity-btn-border">
                Repay
              </button>
            ),
            tooltip: "Repay your loan",
            onClick: (event, rowData) =>
              repayDeck({ deckId: rowData.deckId }).then((response) => {
                if (response.status === 200) {
                  Toast("Successfully repaid deck", "is-success");
                  mixpanel.track('Deck repayment');
                } else {
                  Toast(
                    "Could not repay deck, do you have sufficient balance in your wallet?",
                    "is-danger"
                  );
                }
              }),
          },
        ];
      case "unmatched_borrowing":
        return [
          (rowData) => {
            // return {
            //   icon: () => (
            //     <button className="button white-button has-text-weight-semibold activity-btn-border">
            //       Authorize
            //     </button>
            //   ),
            //   hidden: rowData.confirmed,
            //   tooltip: "Authorize",
            //   onClick: () => handleAuthorize(rowData),
            // };
          },

          {
            icon: () => (
              <button className="button white-button has-text-weight-semibold activity-btn-border">
                Edit
              </button>
            ),
            tooltip: "Edit deck",
            onClick: (event, rowData) => {
              openModal(true);
              setRowData(rowData);
            },
          },
          {
            icon: () => (
              <button className="button primary-button has-text-weight-semibold activity-btn-border">
                Cancel
              </button>
            ),
            tooltip: "Cancel deck?",
            onClick: (event, rowData) => {
              setRowData(rowData);
              handleCancelDeck(rowData?.deckId);
            },
          },
        ];
      case "unmatched_lending":
        return [
          {
            icon: () => (
              <button className="button white-button has-text-weight-semibold activity-btn-border">
                Edit
              </button>
            ),
            tooltip: "Edit deck",
            onClick: (event, rowData) => {
              openModal(true);
              setRowData(rowData);
            },
          },
          {
            icon: () => (
              <button className="button primary-button has-text-weight-semibold activity-btn-border">
                Cancel
              </button>
            ),
            tooltip: "Cancel deck?",
            onClick: (event, rowData) => {
              setRowData(rowData);
              handleCancelDeck(rowData.deckId);
            },
          },
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    const getClosedDecks = async () => {
      return await fetchClosedDecks({
        access_token: user.accessToken,
        type,
      });
    };

    const getActiveDecks = async () => {
      return await fetchActiveDecks({
        access_token: user.accessToken,
        type,
      });
    };

    const getUnmatchedDecks = async () => {
      return await fetchUnmatchedDecks({
        access_token: user.accessToken,
        type,
      });
    };

    if (user) {
      switch (activeTab) {
        case "closed decks":
          getClosedDecks().then((result) => {
            setTableColumns(columns(activeTab, type));
            setTableData(result[type + "s"]);
          });
          break;
        case "active decks":
          getActiveDecks().then((result) => {
            setTableColumns(columns(activeTab, type));
            setTableData(result[type + "s"]);
          });
          break;
        case "unmatched decks":
          getUnmatchedDecks().then((result) => {
            setTableColumns(columns(activeTab, type));
            setTableData(result[type + "s"]);
          });
          break;

        default:
          setTableColumns(columns("closed decks"));
          setTableData([]);
          break;
      }
    }
  }, [user, activeTab, activeSwitch, edited, type]);

  useEffect(() => {
    const _actions = actions(`${activeTab.split(" ")[0]}_${type}`);
    setTableActions(_actions);
  }, [type, activeTab]);

  // confirm direct debit authorization
  useEffect(() => {
    const reference = router.query.tenderToken;
    const status = router.query.status;
    if (reference) {
      setLoading(true);
      let retryCount = 0;
      // I should probably show a loading screen while verifying
      // call to verify payment
      const verify = async () => {
        if (retryCount > 2 && status === "failed") {
          // show error toast and allow user to try again
          return;
        }

        if (retryCount > 2) {
          // return if there is an unexpected error from the server
          // show error toast and indicate that the problem is from the server
          return;
        }

        confirmDirectDebit({ token: reference }).then((response) => {
          // notify server
          if (response) {
            return router.push(`/app/activity`);
          } else {
            console.error(
              `Something went wrong while verifying payment: ${response}`
            );
            console.info("retrying in 3, 2, 1...");
            setTimeout(() => {
              verify();
              retryCount = retryCount + 1;
            }, 3000);
          }
        });
      };
      setLoading(false);
      verify();
    }
  }, [router]);

  return (
    <>
      <Loader state={isLoading} />
      <div className="columns Activity">
        <div className="column">
          <Tabs tabs={tabs} active={(active) => handleToggle(active)} />
        </div>
      </div>

      <div className="columns Activity">
        <div className="column">
          <div className="buttons has-adds">
            <div
              className={`button is-right has-text-weight-semibold ${
                activeSwitch ? "active-switch" : ""
              }`}
              onClick={() => handleSwitch(true)}
            >
              Borrowing
            </div>
            <div
              className={`button is-left has-text-weight-semibold ${
                !activeSwitch ? "active-switch" : ""
              }`}
              onClick={() => handleSwitch(false)}
            >
              Lending
            </div>
          </div>
        </div>
      </div>

      <div className="columns Activity">
        <div className="column">
          <Table
            rows={tableData}
            columns={tableColumns}
            actions={tableActions}
          />
          <Modal
            title="Edit Deck"
            actionButton="Save Changes"
            action={handleEditDeck}
            toggle={toggleOpen}
            open={() => openModal(false)}
            loading={isLoading}
          >
            <EditDeckModal initialData={rowData} data={getData} reset={reset} />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Activity;
