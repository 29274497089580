import React, { useEffect, useState } from "react";
import "./Modal.scss";

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleModalClose = () => {
    setIsOpen(false);
    props.open(false);
  };

  useEffect(() => {
    setIsOpen(props.toggle);
  }, [props.toggle]);

  return (
    <div className={`Modal modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title has-text-weight-bold">{props.title}</p>
          <i
            className="mdi mdi-close-circle-outline is-size-4 cursorBtn primary-color"
            onClick={handleModalClose}
          ></i>
        </header>
        <section className="modal-card-body">{props.children}</section>
        {!props.noFooter && (
          <footer className="modal-card-foot center">
            <div className="move-center">
              <button
                className="button btnCancel has-text-weight-semibold mr-5 pl-0"
                onClick={handleModalClose}
              >
                CANCEL
              </button>
              <button
                className={`button is-primary has-text-weight-semibold mr-5 ${
                  props.loading ? " is-loading " : ""
                }`}
                disabled={props.buttonDisabled}
                onClick={() => props.action()}
              >
                {props.actionButton}
              </button>
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default Modal;
