import React from "react";
import { makeStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import MaterialTable from "material-table";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: "1%",
    color: "#091F5C",
    border: "none",
  },
  container: {
    maxHeight: 440,
  },
});

export default function CustomTable(props) {
  const classes = useStyles();

  const { rows, columns, title, actions } = props;

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <MaterialTable
          title={title || ""}
          columns={columns}
          data={rows}
          actions={actions}
          options={{ toolbar: false, search: false, actionsColumnIndex: -1, headerStyle: {
            backgroundColor: '#F4F5FE',
            color: '#6B799D',
            fontWeight: 'bold',
            border: 'none'
          },
          rowStyle: {
            color: '#091F5C',
            fontWeight: '500'
          },
        }}
        />
      </TableContainer>
    </div>
  );
}
