import { apiRequest } from "./util";

function checkPhone(data) {
  return apiRequest(`user/checkifexists/${data}`, "GET");
}

function verifyToken(data) {
  return apiRequest("user/verify/phoneNumber", "POST", data);
}

function register(data) {
  return apiRequest("user/register", "POST", data);
}

function login(data) {
  return apiRequest("auth/login", "POST", data);
}

function logout() {
  return apiRequest("auth/logout", "POST", { logout: true });
}

function updatePassword(data) {
  return apiRequest("auth/changepwd/initiate", "POST", data);
}

function initiatePasswordRecovery(data) {
  return apiRequest("auth/pwd/recovery/initiate", "POST", data);
}

function checkRecoveryToken(data) {
  return apiRequest("auth/pwd/recovery/authorize", "POST", data);
}

function passwordReset(data) {
  return apiRequest("auth/pwd/recovery/finalize", "POST", data);
}

function getProfile() {
  return apiRequest(`user/load/profile`, "GET", null);
}

function fundWallet(data) {
  return apiRequest("wallet/fund/generate", "POST", data);
}

function getNotifications() {
  return apiRequest("notification/fetch", "GET", null);
}

function getUpdates() {
  return apiRequest("update/fetch", "GET", null);
}

function joinWaitList(data) {
  return apiRequest("subscriber/access/early", "POST", data);
}

export {
  checkPhone,
  verifyToken,
  register,
  login,
  logout,
  getProfile,
  fundWallet,
  getNotifications,
  getUpdates,
  updatePassword,
  checkRecoveryToken,
  passwordReset,
  initiatePasswordRecovery,
  joinWaitList,
};
