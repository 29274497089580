import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  fetchBanks,
  fetchTransferFee,
  resolveBankAccount,
} from "../../util/transactions";

const TransferFromWalletModal = (props) => {
  const { register, control } = useForm();
  const [banks, setBanks] = useState(null);
  const [fee, setFee] = useState(0);
  const [accountName, setAccountName] = useState(null);
  const [insufficientText, setInsufficientText] = useState("");
  const data = useWatch({
    control,
    name: ["accountNumber", "bankCode", "amount", "password"], // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "default", // default value before the render
  });

  props.data({ accountName, ...data });

  const handleFees = async () => {
    const getTransferFee = async () => {
      const fee = await fetchTransferFee({ amount: data?.amount });
      setFee(fee.transferFee);
      if (fee.sufficientBalance === true) {
        props.disabled(false);
        setInsufficientText("");
      } else {
        props.disabled(true);
        setInsufficientText("Insufficient balance to complete this request");
      }
    };

    if (data?.amount?.length > 1) {
      getTransferFee();
    }
  };

  // resolve account number
  useEffect(() => {
    const resolveAccountNumber = async () => {
      const account = await resolveBankAccount({
        accountNumber: data?.accountNumber,
        bankCode: data?.bankCode,
      });
      setAccountName(account?.accountName);
    };

    if (data?.accountNumber?.length === 10 && accountName === null) {
      resolveAccountNumber();
    }
  }, [accountName, data]);

  // fetch bank accounts
  useEffect(() => {
    let mounted = true;
    const getBankAccounts = async () => {
      const accounts = await fetchBanks();
      mounted = false;
      setBanks(accounts);
    };

    if (banks === null) {
      getBankAccounts();
    }
    return () => (mounted = false);
  }, [banks]);

  return (
    <form>
      <label className="label">Bank</label>
      <div className="control select is-fullwidth mb-3">
        <select name="bankCode" ref={register()} className="select">
          {banks &&
            banks?.map((bank) => (
              <option value={bank.code} key={bank.name}>
                {bank.name}
              </option>
            ))}
        </select>
      </div>

      <label className="label">Account Number</label>
      <input
        type="number"
        name="accountNumber"
        ref={register()}
        className="input"
      />

      <label className="label">Account Name</label>
      <input
        name="accountName"
        className="input"
        readOnly={true}
        disabled={true}
        value={accountName}
      />

      <label className="label">Amount</label>
      <input
        type="number"
        name="amount"
        ref={register()}
        className="input"
        defaultValue={0}
      />

      <label className="label is-size-7 mb-3">{`You will be charged: N${parseInt(
        fee
      )} in transaction fees.`}</label>

      <label className="label">Total Charge</label>
      <input
        className="input"
        readOnly={true}
        disabled={true}
        value={`N ${parseFloat(fee) + parseFloat(data?.amount)}`}
      />

      <label className="label">Password</label>
      <input
        onFocus={handleFees}
        type="password"
        name="password"
        ref={register()}
        className="input"
      />
      <br />
      {insufficientText != "" && (
        <div>
          <br />
          <label className="is-size-6" style={{ color: "red" }}>
            {insufficientText}
          </label>
        </div>
      )}
    </form>
  );
};

export default TransferFromWalletModal;
