import React from "react";

const InfoModal = (props) => {
  return (
    <div className="columns">
      <div className="column is-full has-text-centered has-text-primary is-primary">
        {!props.noCheckMark && (
          <i className="far fa-check-circle has-text-primary is-size-1 has-text-weight-light pb-2"></i>
        )}
        <p className="has-text-primary pb-5">{props.info}</p>
      </div>
    </div>
  );
};

export default InfoModal;
