import React from "react";
import { Link } from "./../util/router.js";
import "./SettingsNav.scss";

function SettingsNav(props) {
  return (
    <div
      className={
        "SettingsNav tabs" +
        (props.parentColor === "white" ? " active-tab-text-white" : "")
      }
    >
      <ul>
        <li
          className={"" + (props.activeKey === "profile" ? " is-active" : "")}
        >
          <Link to="/app/settings?section=profile" className={`has-text-weight-semibold is-size-5 mr-4`}>Profile</Link>
        </li>
        <li className={"" + (props.activeKey === "bank" ? " is-active" : "")}>
          <Link to="/app/settings?section=bank" className={`has-text-weight-semibold is-size-5 mr-4`}>Bank</Link>
        </li>
        <li
          className={"" + (props.activeKey === "password" ? " is-active" : "")}
        >
          <Link to="/app/settings?section=password" className={`has-text-weight-semibold is-size-5`}>Password</Link>
        </li>
      </ul>
    </div>
  );
}

export default SettingsNav;
