import React, { useState } from "react";
import { css } from "@emotion/react";
import RingLoader from "react-spinners/FadeLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  position: fixed;
  top: 95%;
  left: 95%;
  transform: translate(-50%, -50%);
  border-color: red;
  z-index: 1000;
`;

const Loader = ({ state }) => {
  const [color, setColor] = useState("#091F5C");
  return <RingLoader color={color} loading={state} css={override} size={50} />;
};

export default Loader;
