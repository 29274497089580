import React, { useState } from "react";
import { Link, useRouter } from "./../util/router.js";
import { useAuth } from "./../util/auth.js";

function Navbar(props) {
  const auth = useAuth();
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const app = router.pathname.includes("/app");
  return (
    <>
      {!app && (
        <nav
          className={
            "navbar" +
            (props.color ? ` is-${props.color}` : "") +
            (props.spaced ? " is-spaced" : "")
          }
        >
          <div className="container">
            <div className="navbar-brand">
              <div className="navbar-item">
                <Link to="/">
                  <img className="image" src={props.logo} alt="Logo" />
                </Link>
              </div>
              <div
                className={
                  "navbar-burger burger" + (menuOpen ? " is-active" : "")
                }
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
              <div className="navbar-end">
                {auth.user && (
                  <div className="navbar-item has-dropdown is-hoverable">
                    <Link className="navbar-link" to="/">
                      Account
                    </Link>
                    <div className="navbar-dropdown is-boxed">
                      <Link className="navbar-item" to="/app">
                        Dashboard
                      </Link>
                      <Link className="navbar-item" to="/settings/general">
                        Settings
                      </Link>
                      <hr className="dropdown-divider" />
                      <Link
                        className="navbar-item"
                        to="/auth/signout"
                        onClick={(e) => {
                          e.preventDefault();
                          auth.signout();
                        }}
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                )}

                {!auth.user && process.env.NODE_ENV !== "production" && (
                  <Link className="navbar-item" to="/auth/signin">
                    Sign in
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default Navbar;
