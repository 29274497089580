import React, { useEffect, useState } from "react";

const DeleteBankModal = (props) => {
  return (
    
    <div className="p-5">
      <div className="subtitle has-text-weight-semibold py-5 primary-color move-center">
        Are you sure you want to delete your bank account details?
      </div>
    </div>
  );
};

export default DeleteBankModal;
