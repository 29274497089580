import React, { Suspense, useMemo, useEffect } from "react";
import DashboardSection from "../components/DashboardSection";
import { requireAuth, useAuth } from "../util/auth.js";
import { useRouter } from "../util/router";
import routes from "../util/routes.js";
import Sidebar from "../components/Sidebar";
import Section from "../components/Section";
import { Redirect, Route, Switch } from "react-router";
import { linkMonoAccount } from "../util/transactions";
import MonoConnect from "@mono.co/connect.js";
import Toast from "../components/Toast";
import { useMediaQuery } from "react-responsive";
import tenderLogo from "../assets/img/logo.png";
import deskLogo from "../assets/img/create_account.png";
import { getProfile } from "../util/user";

function AppPage() {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 1025 });
    return isNotMobile ? children : null;
  };

  const { user } = useAuth();
  const router = useRouter();

  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => true,
      onLoad: () => true,
      onSuccess: async ({ code }) => {
        await linkMonoAccount({
          access_token: user.access_token,
          uid: user.uid,
          code,
        })
          .then(() => Toast("Linked account successfully!", "is-success"))
          .catch(() =>
            Toast(
              "Trouble linking your account, please try again!",
              "is-danger"
            )
          );
      },
      key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    });
    monoInstance.setup();
    return monoInstance;
  }, [user]);

  useEffect(() => {
    if (user?.hasLinkedAccount === false) {
      monoConnect.open();
    }
  }, [user, monoConnect]);

  useEffect(() => {
    const callCheck = async () => {
      await getProfile();
    };
    callCheck();
  }, [router.pathname]);

  return (
    <div>
      <Desktop>
        <Section>
          <div className="columns">
            <div className="column is-one-fifth sticky-sidebar">
              <Sidebar items={routes} />
            </div>
            <div className="column is-four-fifth">
              <DashboardSection>
                <Suspense fallback={<span className="loading"></span>}>
                  <Switch>
                    {routes.map((route) => {
                      return (
                        <Route
                          key={route.activeLink}
                          path={route.activeLink}
                          component={route.component}
                        ></Route>
                      );
                    })}
                    <Redirect strict from="/app" to="/app/dashboard" />
                  </Switch>
                </Suspense>
              </DashboardSection>
            </div>
          </div>
        </Section>
      </Desktop>

      <Mobile>
        <div
          style={{
            padding: "40% 10% 10% 10%",
            textAlign: "center",
            color: "rgb(190,190,190)",
            fontSize: "18px",
          }}
        >
          <img
            src={tenderLogo}
            alt="Tender logo"
            style={{
              width: "50%",
              height: "auto",
              display: "block",
              marginLeft: "25%",
            }}
          />
          <br />
          <br />
          <br />
          <img
            src={deskLogo}
            alt="Tender logo"
            style={{
              width: "50%",
              height: "auto",
              display: "block",
              marginLeft: "25%",
            }}
          />
          <br />
          <span
            style={{
              color: "#6B799D",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            Hello,
          </span>
          <br />
          <br />
          The App is optimized for
          <br />
          Desktop View Only
          <br />
          <br />
        </div>
      </Mobile>
    </div>
  );
}

export default requireAuth(AppPage);
