import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from "react";
import queryString from "query-string";
import fakeAuth from "fake-auth";
import { useUser, updateUser } from "./db";
import {
  checkPhone,
  verifyToken,
  register,
  login,
  logout,
  getProfile,
  updatePassword as changePassword,
  checkRecoveryToken,
  passwordReset,
  initiatePasswordRecovery,
} from "./user";

import { history } from "./router";
import PageLoader from "./../components/PageLoader";

import analytics from "./analytics";
import Toast from "../components/Toast";

// Whether to merge extra user data from database into auth.user
const MERGE_DB_USER = false;

// Whether to connect analytics session to user.uid
const ANALYTICS_IDENTIFY = false;

const authContext = createContext();

// initialize cookies

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider() {
  // Store auth user object
  const [user, setUser] = useState(null);

  // Store the phone verification token
  const [pinId, setPinId] = useState(null);

  // Format final user object and merge extra data from database
  const finalUser = usePrepareUser(user);

  // Connect analytics session to user
  useIdentifyUser(finalUser);

  // Handle response from authentication functions
  const handleAuth = async () => {
    const user = await getProfile();
    sessionStorage.setItem("user", JSON.stringify(user));
    // Update user in state
    setUser(user);
  };

  const handleCheckPhone = async (pin) => {
    setPinId(pin);
  };

  const checkPhoneNumber = async (phoneNumber) => {
    return checkPhone(phoneNumber).then(async (response) => {
      await handleCheckPhone(response.pinId);
      return response;
    });
  };

  const verifyPin = async (pin) => {
    const response = await verifyToken({ pin, pinId });
    return response;
  };

  const signup = (data) => {
    return register({
      verificationToken: data.token,
      phoneNumber: data.phone,
      password: data.pass,
      firstName: data.firstname,
      email: data.email,
    }).then((response) => response);
  };

  const signin = async (phone, password) => {
    const response = await login({ phoneNumber: phone, password: password });
    if (response) {
      sessionStorage.setItem("sessionTimer", 1740000);
      await handleAuth();
    }
    return response;
  };

  const signinWithProvider = (name) => {
    return fakeAuth
      .signinWithProvider(name)
      .then((response) => handleAuth(response.user));
  };

  const signout = async () => {
    await logout();
    setUser(false);
    sessionStorage.clear();
  };

  const sendPasswordResetEmail = (email) => {
    return initiatePasswordRecovery({ email });
  };

  const validateRecoveryToken = async (token) => {
    return await checkRecoveryToken({ token: token ? true : false });
  };

  const confirmPasswordReset = (password, code) => {
    // [INTEGRATING AN AUTH SERVICE]: If not passing in "code" as the second
    // arg above then make sure getFromQueryString() below has the correct
    // url parameter name (it might not be "code").

    // Get code from query string object
    const resetCode = code || getFromQueryString("code");
    return passwordReset({ newPassword: password, stage2Token: resetCode });
  };

  const updateEmail = (email) => {
    return fakeAuth.updateEmail(email).then((rawUser) => {
      setUser(rawUser);
    });
  };

  const updatePassword = (passwords) => {
    return changePassword(passwords);
  };

  // Update auth user and persist to database (including any custom values in data)
  // Forms can call this function instead of multiple auth/db update functions
  const updateProfile = async (data) => {
    const { email, name, picture } = data;

    // Update auth email
    if (email) {
      await fakeAuth.updateEmail(email);
    }

    // Update auth profile fields
    if (name || picture) {
      let fields = {};
      if (name) fields.name = name;
      if (picture) fields.picture = picture;
      await fakeAuth.updateProfile(fields);
    }

    // Persist all data to the database
    await updateUser(user.uid, data);

    // Update user in state
    const currentUser = await fakeAuth.getCurrentUser();
    setUser(currentUser);
  };

  useEffect(() => {
    // Subscribe to user on mount
    const sessionUser = JSON.parse(sessionStorage.getItem("user"));
    setUser(sessionUser);
    sessionStorage.setItem("user", JSON.stringify(sessionUser));
  }, []);

  return {
    user: finalUser,
    signup,
    signin,
    signinWithProvider,
    signout,
    checkPhoneNumber,
    verifyPin,
    sendPasswordResetEmail,
    confirmPasswordReset,
    updateEmail,
    updatePassword,
    updateProfile,
    validateRecoveryToken,
  };
}

// Format final user object and merge extra data from database
function usePrepareUser(user) {
  // Fetch extra data from database (if enabled and auth user has been fetched)
  const userDbQuery = useUser(MERGE_DB_USER && user && user.uid);

  // Memoize so we only create a new object if user or userDbQuery changes
  return useMemo(() => {
    // Return if auth user is null (loading) or false (not authenticated)
    if (!user) return user;

    // Data we want to include from auth user object
    let finalUser = {
      // email: user.email,
      name: user.name,
      picture: user.picture,
      ...user,
    };

    // Include an array of user's auth providers, such as ["password", "google", etc]
    // Components can read this to prompt user to re-auth with the correct provider
    finalUser.providers = [user.provider];

    // If merging user data from database is enabled ...
    if (MERGE_DB_USER) {
      switch (userDbQuery.status) {
        case "idle":
          // Return null user until we have db data to merge
          return null;
        case "loading":
          return null;
        case "error":
          // Log query error to console
          console.error(userDbQuery.error);
          return null;
        case "success":
          // If user data doesn't exist we assume this means user just signed up and the createUser
          // function just hasn't completed. We return null to indicate a loading state.
          if (userDbQuery.data === null) return null;

          // Merge user data from database into finalUser object
          Object.assign(finalUser, userDbQuery.data);

        // no default
      }
    }

    return finalUser;
  }, [user, userDbQuery]);
}

// A Higher Order Component for requiring authentication
export const requireAuth = (Component) => {
  return (props) => {
    // Get authenticated user
    const auth = useAuth();

    useEffect(() => {
      // Redirect if not signed in
      if (auth.user === false || auth.user === "false") {
        history.replace("/auth/signin");
      }
      // Show loading indicator
      // We're either loading (user is null) or we're about to redirect (user is false)
      if (!auth.user) {
        return <PageLoader />;
      }
    }, [auth]);

    // Render component now that we have user
    return <Component {...props} />;
  };
};

// Connect analytics session to current user.uid
function useIdentifyUser(user) {
  useEffect(() => {
    if (ANALYTICS_IDENTIFY && user) {
      analytics.identify(user.uid);
    }
  }, [user]);
}

const getFromQueryString = (key) => {
  return queryString.parse(window.location.search)[key];
};
