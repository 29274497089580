import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Modal.scss";

const AuthModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { register, handleSubmit } = useForm();
  const handleModalClose = () => {
    setIsOpen(false);
    props.open(false);
  };

  useEffect(() => {
    setIsOpen(props.toggle);
  }, [props.toggle]);

  const onSubmit = (formData) => {
    props.data(formData);
    handleModalClose();
  };

  return (
    <div className={`Modal modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title has-text-primary">
            Kindly authenticate this action
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={handleModalClose}
          ></button>
        </header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="modal-card-body">
            <label className="label">Password</label>
            <input type="password" ref={register} name="password" />
          </section>
          {!props.noFooter && (
            <footer className="modal-card-foot ">
              <button
                className="button is-pulled-left"
                onClick={handleModalClose}
              >
                Cancel
              </button>
              <button
                className={`button is-primary is-pulled-right ${
                  props.loading ? " is-loading " : ""
                }`}
                type="submit"
              >
                {props.actionButton}
              </button>
            </footer>
          )}
        </form>
      </div>
    </div>
  );
};

export default AuthModal;
