import React, { useEffect, useState } from "react";

const AddCardModal = (props) => {
  return (
    <form>
      <div className="addBankNav mb-3 p-2">
       <div className="primary-color is-size-7 mb-1">Card Expiry</div>
       <input
          type="number"
          name="accountNumber"
          className="input addBankInput"
          placeholder="0000 0000 0000 0000"
        />
      </div>

      <div className="columns">
        <div className="column">
          <div className="addBankNav p-2">
            <div className="primary-color is-size-7 mb-1">Card Expiry</div>
            <input
            type="number"
            name="MMYY"
            className="input addBankInput"
            placeholder="MM/YY"
          />
          </div>
        </div>


        <div className="column">
          <div className="addBankNav p-2">
            <div className="primary-color is-size-7 mb-1">CVV</div>
            <input
            type="number"
            name="CVV"
            className="input addBankInput"
            placeholder="000"
          />
          </div>
        </div>
      </div>


      

    </form>
  );
};

export default AddCardModal;
