import React from "react";
import RowCard from "./RowCard";

import handShake from "../../assets/img/handshake.png";
import man from "../../assets/img/man.png";
import phones from "../../assets/img/phones.png";
import atm from "../../assets/img/atm_card.png";
import "./WhyTender.css";

export default function WhyTender() {
  return (
    <div className="whyTender">
      <h2>Why Tender</h2>
      <div
        className="rowCard" style={{ height: "auto" }}
      >
        <div className="rowCard__text">
          <div className="rowCard__textBox">
            <h4> Easy and convenient to use</h4>
            <p></p>
            <p></p>
            <p>You can get a loan quickly and easily.</p>
          </div>
        </div>

        <div className="rowCard__imgBox">
          <img src={man} alt="" className="rowCard__img" />
        </div>
      </div>

      <RowCard
        image={phones}
        title="Control over loan terms"
        subtitle="The amount, rate and duration are all decided by you."
        bgColor="#001E5A"
      />

      <RowCard
        image={handShake}
        title="0% default guarantee"
        subtitle="Your funds are safe on Tender and your return on investments are completely guaranteed by Tender"
      />

      <RowCard
        image={atm}
        title="No hidden charges"
        subtitle="We are 100% transparent with charges on Tender."
        bgColor="#001E5A"
      />
    </div >
  );
}
