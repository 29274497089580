import React, { useEffect, useState } from "react";
import Modal from "../components/Modal";
import AddBankModal from "../components/modals/AddBankModal";
import AddCardModal from "../components/modals/AddCardModal";
import DeleteBankModal from "../components/modals/DeleteBankModal";
import "./SettingsBank.scss";
import defaultCard from "../assets/img/default-card.png";
import { fetchBankAccounts } from "../util/transactions";

const SettingsBank = () => {
  const [bankToggleOpen, setBankToggleOpen] = useState(false);
  const [cardToggleOpen, setCardToggleOpen] = useState(false);
  const [deleteToggleOpen, setDeleteToggleOpen] = useState(false);
  const [bankAccounts, setBankAccounts] = useState(null);

  const openBankModal = (value) => {
    setBankToggleOpen(value);
  };
  const openCardModal = (value) => {
    setCardToggleOpen(value);
  };
  const openDeleteModal = (value) => {
    setDeleteToggleOpen(value);
  };

  // fetch bank accounts
  useEffect(() => {
    const getBankAccounts = async () => {
      const accounts = await fetchBankAccounts();
      setBankAccounts(accounts?.linkedAccounts);
    };

    if (bankAccounts === null) {
      getBankAccounts();
    }
  }, [bankAccounts]);
  return (
    <div className="SettingsBank columns mt-5">
      <Modal
        title="Add bank account"
        actionButton="VERIFY"
        toggle={bankToggleOpen}
        open={() => openBankModal(false)}
      >
        <AddBankModal />
      </Modal>

      <Modal
        title="Add your card"
        actionButton="VERIFY"
        toggle={cardToggleOpen}
        open={() => openCardModal(false)}
      >
        <AddCardModal />
      </Modal>

      <Modal
        title="Delete your bank account"
        actionButton="CONFIRM"
        toggle={deleteToggleOpen}
        open={() => openDeleteModal(false)}
      >
        <DeleteBankModal />
      </Modal>
      {/*Bank Section*/}
      <div className="column mr-5">
        <div className="column is-full settingsBankTop mb-2">
          <div className="columns">
            <div className="column primary-color has-text-weight-bold">
              Banks
            </div>
            <div
              className="column bankBtn is-7 has-text-weight-bold primary-color move-center"
              onClick={() => openBankModal(true)}
            >
              ADD BANK
            </div>
          </div>
        </div>

        <div className="column is-full settingsBankMiddle">
          <div>
            <div className="columns">
              <div className="column pb-0">
                <div className="grey">Bank</div>
                <div className="has-text-weight-bold primary-color">
                  {bankAccounts && bankAccounts[0]?.bankName}
                </div>
              </div>

              <div className="column has-text-right">
                {/* <img src={bankLogo} alt="default card" className="bankLogo" /> */}
              </div>
            </div>
          </div>

          <div className="mb-3 mt-3">
            <div className="columns">
              <div className="column">
                <div className="grey">Account Number</div>
                <div className="has-text-weight-bold primary-color">
                  {bankAccounts && bankAccounts[0]?.accountNumber}
                </div>
              </div>

              <div className="column">
                <div className="grey has-text-right">BVN</div>
                <div className="has-text-weight-bold primary-color has-text-right">
                  {bankAccounts && bankAccounts[0]?.bvn}
                </div>
              </div>
            </div>
          </div>

          <div className="has-text-weight-semibold grey is-size-5">
            {bankAccounts && bankAccounts[0]?.accountName}
          </div>
        </div>

        <div className="column is-full settingsBankBottom">
          <div className="columns">
            <div className="column has-text-weight-semibold is-size-5">
              <i className="mdi mdi-currency-ngn mr-2 is-size-4 iii primary-color"></i>{" "}
              Naira
            </div>

            <div
              className="column has-text-right is-size-5 cursorBtn"
              onClick={() => openDeleteModal(true)}
            >
              <i className="mdi mdi-trash-can-outline mr-2 is-size-4"></i>{" "}
              Delete
            </div>
          </div>
        </div>
      </div>

      {/*Card Section*/}

      <div className="column ml-3 mr-3 is-invisible">
        <div className="column is-full settingsBankTop mb-2 ">
          <div className="columns">
            <div className="column primary-color has-text-weight-bold">
              Cards
            </div>
            <div
              className="column bankBtn is-7 has-text-weight-bold primary-color move-center"
              onClick={() => openCardModal(true)}
            >
              ADD CARD
            </div>
          </div>
        </div>

        <div className="column is-full settingsBankMiddle is-vcentered move-center">
          <img src={defaultCard} alt="default card" className="bankImg mb-5" />
          <div className="grey is-size-6">
            You havent added any cards yet. You can do this by clicking on the
            button above
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsBank;
