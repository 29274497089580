const columns = (deck, type) => {
  switch (deck) {
    case "closed decks":
      return [
        {
          field: "createdAt",
          title: "Deck Created Date",
          render: (value) => value && value?.createdAt?.split("T")[0],
        },
        {
          field: "createdAt",
          title: "Loan Start Date",
          render: (value) => value && value?.createdAt?.split("T")[0],
        },
        {
          field: "amount",
          title: "Loan Amount",
          render: (value) =>
            value ? `N${value?.amount?.toLocaleString("en-US")}` : "",
        },
        {
          field: "interestRate",
          title: "Interest Rate",
          render: (value) => value && `${value?.interestRate}%`,
        },
        {
          field:
            type === "borrowing" ? "borrowerInterest" : "lenderActInterest",
          title: "Interest Amount",
          render: (value) => {
            if (type === "borrowing") {
              return value
                ? `N${value?.borrowerInterest?.toLocaleString("en-US")}`
                : "";
            } else {
              return value
                ? `N${value?.lenderActInterest?.toLocaleString("en-US")}`
                : "";
            }
          },
        },
        {
          field: "tenor",
          title: "Days",
          render: (value) => value && `${value?.tenor} days`,
        },
        {
          field: "loan_repaid_date",
          title: "Loan Repaid Date",
        },
      ];
    case "active decks":
      return [
        {
          field: "createdAt",
          title: "Deck Created Date",
          render: (value) => value && value?.createdAt?.split("T")[0],
        },
        {
          field: "createdAt",
          title: "Loan Start Date",
          render: (value) => value && value?.createdAt?.split("T")[0],
        },
        {
          field: "amount",
          title: "Loan Amount",
          render: (value) =>
            value ? `N${value?.amount?.toLocaleString("en-US")}` : "",
        },
        {
          field: "interestRate",
          title: "Interest Rate",
          render: (value) => value && `${value?.interestRate}%`,
        },
        {
          field:
            type === "borrowing" ? "borrowerInterest" : "lenderActInterest",
          title: "Interest Amount",
          render: (value) => {
            if (type === "borrowing") {
              return value
                ? `N${value?.borrowerInterest?.toLocaleString("en-US")}`
                : "";
            } else {
              return value
                ? `N${value?.lenderActInterest?.toLocaleString("en-US")}`
                : "";
            }
          },
        },
        {
          field: "tenor",
          title: "Days",
          render: (value) => value && `${value?.tenor} days`,
        },
        {
          field: "loan_due_date",
          title: "Loan Due Date",
        },
      ];
    case "unmatched decks":
      return [
        {
          field: "createdAt",
          title: "Deck Created Date",
          render: (value) => value && value?.createdAt?.split("T")[0],
        },
        {
          field: "amount",
          title: "Loan Amount",
          render: (value) =>
            value ? `N${value?.amount?.toLocaleString("en-US")}` : "",
        },
        {
          field: "interestRate",
          title: "Interest Rate",
          render: (value) => value && `${value?.interestRate}%`,
        },
        {
          field:
            type === "borrowing" ? "borrowerInterest" : "lenderActInterest",
          title: "Interest Amount",
          render: (value) => {
            if (type === "borrowing") {
              return value
                ? `N${value?.borrowerInterest?.toLocaleString("en-US")}`
                : "";
            } else {
              return value
                ? `N${value?.lenderActInterest?.toLocaleString("en-US")}`
                : "";
            }
          },
        },
        {
          field: "tenor",
          title: "Days",
          render: (value) => value && `${value?.tenor} days`,
        },
      ];

    default:
      return [];
  }
};

export { columns };
