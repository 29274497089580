import React, { useMemo, useState } from "react";
import "./Sidebar.scss";
import "../styles/dashboard.scss";
import { Link, useRouter } from "../util/router";
import tenderLogo from "../assets/img/logo.png";

const Sidebar = (props) => {
  const items = props.items;
  const [activeLink, setActiveLink] = useState("/app");
  const router = useRouter();
  const pathname = router.pathname;

  useMemo(() => {
    switch (pathname) {
      case "/app/marketplace":
        setActiveLink("/app/marketplace");
        break;
      case "/app/create-deck":
        setActiveLink("/app/create-deck");
        break;
      case "/app/activity":
        setActiveLink("/app/activity");
        break;
      case "/app/settings":
        setActiveLink("/app/settings");
        break;

      default:
        setActiveLink("/app/dashboard");
        break;
    }
  }, [pathname]);

  return (
    <aside className="menu sticky" style={{}}>
      <img src={tenderLogo} alt="Tender logo" className="tenderLogo mb-4" />
      <ul className="menu-list ml-1 mt-5">
        {items?.map(
          (item) =>
            item.title && (
              <li
                className={`is-clickable sidebar-link ${
                  activeLink === item.activeLink ? "link-is-active" : ""
                } mb-4`}
                key={item.title}
              >
                <Link to={item.activeLink}>
                <div className="button sidebar-buttons p-0 ml-2">
                  <i className={`${item.icon} pr-3 is-size-3`}></i>
                  <span className="has-text-weight-semibold">{item.title}</span>
                </div>
                </Link>
              </li>
            )
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
