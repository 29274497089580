import React, { useEffect, useState } from "react";
import "./Tabs.scss";

const Tabs = (props) => {
  const { active, tabs } = props;
  const [activeTab, setActiveTab] = useState(tabs[0].name.toLowerCase());
  const handleSwitch = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    active && active(activeTab);
  });

  return (
    <div className="tabs Tabs mb-3">
      <ul>
        {tabs.map((tab) => {
          return (
            <li
              className={`${
                activeTab === tab.name?.toLowerCase() ? "is-active" : ""
              }`}
              key={tab.name}
            >
              <a href onClick={() => handleSwitch(tab.name?.toLowerCase())} className={`has-text-weight-semibold is-size-5 mr-4`}>
                {tab.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;