import React, { useState } from "react";
import "./Notification.scss";

const Notification = ({ data }) => {
  const [read, setRead] = useState(false);

  const handleHover = () => {
    setRead(true);
  };

  return (
    <div
      className="Notification dropdown is-hoverable mr-3"
      onMouseOver={handleHover}
    >
      <div className="dropdown-trigger">
        <button
          className="button mr-3 bdr"
          aria-haspopup="true"
          aria-controls="dropdown-menu2"
        >
            <i
              className="mdi mdi-bell-outline mr-1 has-text-weight-semibold is-size-4 grey" 
              aria-hidden="true" 
              style={
                !read ? {} : {}
              }
            ></i>
          <span className="has-text-weight-semibold mr-3 grey">Notifications</span>
        </button>
      </div>
      <div
        className="Notification__menu dropdown-menu"
        id="dropdown-menu2"
        role="menu"
      >
        {(data?.length === 0 || !data) && (
          <div className="dropdown-content">
            <div className="dropdown-item">
              <p>
                You have <strong>0</strong> notifications at the moment
              </p>
            </div>
          </div>
        )}

        {data?.length > 0 && (
          <div className="dropdown-content">
            {data.map((notification) => {
              return (
                <>
                  <div className="dropdown-item">
                    <p>{notification.message}</p>
                  </div>
                  <hr className="dropdown-divider" />
                </>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
