import React from "react";
import "./Card.css";

export default function Card({ image, title, pararaph }) {
  return (
    <div className="card">
      <div className="card__imgBox">
        <img src={image} alt="" className="card__img" />
      </div>
      <br />

      <div className="card__texts">
        <h4>{title}</h4>
        <br />
        <p>{pararaph}</p>
        <br />
      </div>
    </div>
  );
}
