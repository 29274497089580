import { toast } from "bulma-toast";

const Toast = (message, type) => {
  return toast({
    message,
    type,
    position: "bottom-right",
    closeOnClick: true,
    duration: 3000,
    opacity: 0.8,
    animate: { in: "fadeIn", out: "fadeOut" },
  });
};

export default Toast;
