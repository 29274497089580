import React, { useState } from "react";
import "./Hero.css";
import logo from "../../assets/img/logo copy.png";
import banner from "../../assets/img/banner.png";
import FormModal from "../FormModal";
import newsletter from "../../util/newsletter";
import mixpanel from "mixpanel-browser";

export default function Hero() {
  const [open, setOpen] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const handleSubmit = async (data) => {
    setSubscribed(true);
    await newsletter.subscribe(data);
    // await joinWaitlist(data);
  };
  return (
    <div className="hero">
      <FormModal
        open={() => setOpen(false)}
        toggle={open}
        getData={handleSubmit}
        isSubmitted={subscribed}
      />
      {/* navigation bar */}
      <div className="navbar">
        <div className="navbar__logo">
          <img src={logo} alt="Tender" className="logo" />
        </div>
      </div>

      <div className="banner__imgBox2">
        <div className="banner__imgWrapper2">
          <img src={banner} alt="Tender" className="banner__img" />
        </div>
      </div>

      {/* Banner */}
      <div className="banner">
        <div className="banner__text">
          <div>
            <h1>Lend and Borrow on your own terms</h1>
            <br />
            <p style={{ fontSize: 18 }}>
              We connect verified borrowers who need short-term loans with
              lenders who are looking to make extra income.
            </p>
            <br />
            <button className="banner__button" onClick={() => {
              setOpen(true);
              mixpanel.track('Request early access');
            }}>Get early access</button>
            <br />
            <br />
          </div>
        </div>

        <div className="banner__imgBox">
          <div className="banner__imgWrapper">
            <img src={banner} alt="Tender" className="banner__img" />
          </div>
        </div>
      </div>
    </div>
  );
}
