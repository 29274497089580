import React, { useEffect, useState, useMemo } from "react";
import "../styles/createdeck.scss";
import borrowImage from "../assets/img/borrow-image.png";
import lendImage from "../assets/img/lend-image.png";
import Tabs from "../components/Tabs";
import MonoConnect from "@mono.co/connect.js";
import { linkMonoAccount } from "../util/transactions";
import Modal from "../components/Modal";
import AuthModal from "../components/AuthModal";
import InfoModal from "../components/modals/InfoModal";
import CreateDeckPreviewModal from "../components/modals/CreateDeckPreviewModal";
import Loader from "../components/Loader";
import { useAuth } from "../util/auth";
import { useRouter } from "../util/router";
import { useForm } from "react-hook-form";
import {
  uploadBasicBorrowingDeck,
  initiateCreateBorrowing,
  preloadDecks,
  authorizeDirectDebit,
  confirmDirectDebit,
} from "../util/transactions";
import Toast from "../components/Toast";
import { getProfile } from "../util/user";
import mixpanel from "mixpanel-browser";

const Createdeck = () => {
  const auth = useAuth();
  const user = auth.user;
  const router = useRouter();
  const [activeTab, setActiveTab] = useState("borrow");
  const [activeImage, setActiveImage] = useState(borrowImage);
  const [toggleOpen, setToggleOpen] = useState(false);
  const [infoToggleOpen, setInfoToggleOpen] = useState(false);
  const [toggleAuthOpen, setToggleAuthOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [payload, setPayload] = useState();
  const [preLoadedDecks, setPreLoadedDecks] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm();
  const tabs = [{ name: "Borrow" }, { name: "Lend" }];

  const openModal = (value) => {
    setToggleOpen(value);
  };

  const openAuthModal = (value) => {
    setToggleAuthOpen(value);
  };

  const openSuccessModal = (value) => {
    setInfoToggleOpen(value);
  };

  const handleSwitch = (value) => {
    setActiveTab(value);
  };

  const createRequest = async (password = null, payload = null) => {
    let data;
    if (payload) {
      setLoading(true);
      data = {
        currency: "NGN",
        tenorUnit: "days",
        type: activeTab.toLowerCase() + "ing",
        ...payload,
      };
      if (activeTab === "lend") {
        const response = await uploadBasicBorrowingDeck(data);
        openModal(false);
        if (response) {
          Toast("Successfully created a deck", "is-success");
          mixpanel.track('Create lending deck');
        } else {
          Toast(
            "There was a problem funding your deck, please try again",
            "is-danger"
          );
        }
        setLoading(false);
      } else if (activeTab === "borrow") {
        const response = await initiateCreateBorrowing(data);
        console.log(response);
        if (response) {
          openModal(false);
          window.open(response.paymentLink, "_self");
          mixpanel.track('Create borrowing deck');
        } else {
          Toast(
            "We could not initialize direct debit, please try again",
            "is-danger"
          );
        }
        setLoading(false);
      }
    }
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    // check if there are matching Decks
    const result = await preloadDecks({
      ...formData,
      amount: parseInt(formData.amount),
      interestRate: parseInt(formData.interestRate),
      tenor: parseInt(formData.tenor),
      currency: "NGN",
      tenorUnit: "days",
      type: activeTab.toLowerCase() + "ing",
    });
    if (result.decks.length > 0) {
      // load pre upload decks
      setPreLoadedDecks(result.decks);
      openModal(true);
    } else if (result.decks.length === 0) {
      // set the payload
      const payload = {
        ...formData,
        amount: parseInt(formData.amount),
        interestRate: parseInt(formData.interestRate),
        tenor: parseInt(formData.tenor),
      };
      await createRequest(null, payload);
      setPayload(payload);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (activeTab === "borrow") {
      setActiveImage(borrowImage);
    } else if (activeTab === "lend") {
      setActiveImage(lendImage);
    }
  }, [activeTab]);

  useEffect(() => {
    const reference = router.query.tenderToken;
    const status = router.query.status;
    const reason = router.query.reason;
    if (reference) {
      let retryCount = 0;
      setLoading(true);
      // call to verify payment
      const verify = async () => {
        if (retryCount > 2 && status === "failed") {
          // show error toast and allow user to try again
          Toast("Could not complete direct debit", "is-danger");
          return;
        }

        if (retryCount > 2) {
          // return if there is an unexpected error from the server
          return;
        }

        if (retryCount < 3 && status === "failed") {
          if (reason === "widget_closed") {
            Toast(
              "You did not finish direct debit authorization, kindly start again",
              "is-danger"
            );
          } else {
            Toast(
              "Could not complete direct debit, kindly try again",
              "is-danger"
            );
          }
        } else {
          confirmDirectDebit({ token: reference }).then((response) => {
            // reset form and notify
            if (response) {
              reset();
              Toast("All done! Your deck is in the marketplace!", "is-success");
              return router.push(`/app/create-deck`);
            } else {
              console.info(`Something went wrong while verifying payment`);
              console.info("retrying in 3, 2, 1...");
              setTimeout(() => {
                verify();
                retryCount = retryCount + 1;
              }, 3000);
            }
          });
        }
      };
      setLoading(false);
      verify();
    }
  }, [router]);

  const monoConnect = useMemo(() => {
    const monoInstance = new MonoConnect({
      onClose: () => true,
      onLoad: () => true,
      onSuccess: async ({ code }) => {
        await linkMonoAccount({
          access_token: user.access_token,
          uid: user.uid,
          code,
        })
          .then(async () => {
            await getProfile();
            Toast("Linked account successfully!", "is-success");
          })
          .catch(() =>
            Toast(
              "Trouble linking your account, please try again!",
              "is-danger"
            )
          );
      },
      key: process.env.REACT_APP_MONO_PUBLIC_KEY,
    });
    monoInstance.setup();
    return monoInstance;
  }, [user]);

  return (
    <div className="Createdeck">
      <Loader state={isLoading} />
      <h1 className="is-size-4 has-text-primary mb-3 has-text-weight-semibold mt-2">
        Can’t find a suitable offer on the Marketplace?
      </h1>
      <p className="is-size-5 has-text-primary mb-5">
        Make your own by entering your own terms and upload for others to see in
        the Marketplace.
      </p>

      {/* Modal */}
      <Modal
        title="Hello,"
        actionButton="Create Deck Anyway"
        toggle={toggleOpen}
        open={() => openModal(false)}
        loading={isLoading}
        action={
          activeTab === "borrow"
            ? () => createRequest(null, payload)
            : () => openAuthModal(true)
        }
      >
        <CreateDeckPreviewModal data={preLoadedDecks} />
      </Modal>

      {/* Success message */}
      <Modal
        toggle={infoToggleOpen}
        noFooter
        open={() => openModal(false)}
        loading={isLoading}
      >
        <InfoModal info="Successful!" />
      </Modal>

      {/* Authentication Modal */}
      <AuthModal
        actionButton="Authenticate"
        toggle={toggleAuthOpen}
        open={() => openAuthModal(false)}
        data={(password) => createRequest(password, payload)}
      />

      {/* deck component */}
      {/* Tabs */}
      <Tabs tabs={tabs} active={handleSwitch} />
      <div className="columns">
            <div className="column deck-image has-text-centered content is-vcentered move-center">
              <figure className="image is-inline-block">
                <img src={activeImage} alt="borrow deck" />
              </figure>
            </div>

            <div className="column deck-form px-5 py-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className="is-size-5 has-text-weight-semibold mb-4 grey">{`${
                  activeTab.charAt(0).toUpperCase() + activeTab.slice(1)
                } some money!`}</h1>
                <div className="field">
                  <label className="label is-size-6 grey has-text-weight-semibold">
                    AMOUNT
                  </label>
                  {errors.name && (
                    <p className="help is-danger">{errors.name}</p>
                  )}
                  <div className="control">
                    <input
                      className="input input-deck p-4"
                      type="number"
                      placeholder="Maximum of N200,000"
                      name="amount"
                      ref={register({ required: "Please enter an amount" })}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label is-size-6 grey has-text-weight-semibold">
                    INTEREST
                  </label>
                  <div className="control">
                    <input
                      className="input input-deck p-4"
                      type="number"
                      placeholder="3%"
                      name="interestRate"
                      ref={register}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label is-size-6 grey has-text-weight-semibold">
                    TENOR
                  </label>
                  <div className="control">
                    <input
                      className="input input-deck p-4"
                      type="number"
                      placeholder="5 days"
                      name="tenor"
                      ref={register}
                    />
                  </div>
                </div>

                <div className="field move-center mt-2 input-deck-sub">
                  <button
                    className="button is-primary px-5 py-4 has-text-weight-bold"
                    type="submit"
                  >
                    CREATE
                  </button>
                </div>
              </form>
            </div>
      </div>
    </div>
  );
};

export default Createdeck;
