import React, { useState } from "react";
import FormField from "./FormField";
import { useAuth } from "./../util/auth.js";
import { useForm } from "react-hook-form";
import Toast from "../components/Toast";

function SettingsPassword(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    auth.updatePassword(data).then((response) => {
      if (response.status === 403) {
        Toast("Your old password is incorrect", "is-danger");
      }

      if (response.pwdChanged === true) {
        Toast("Successfully update password", "is-success");
        reset();
      }
      setPending(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <FormField
        name="password"
        type="settingsPassword"
        tag="Old Password"
        className="input-settings"
        placeholder="Old Password"
        error={errors.pass}
        inputRef={register({
          required: "Please enter a password",
        })}
      />
      <FormField
        name="newPassword"
        type="settingsPassword"
        tag="New Password"
        className="input-settings"
        placeholder="Confirm New Password"
        error={errors.confirmPass}
        inputRef={register({
          required: "Please enter your new password",
        })}
      />
      <div className="field">
        <div className="control">
          <button
            className={
              "button is-size-6 has-text-weight-semibold settingsBtnSave" +
              (props.buttonColor ? ` is-${props.buttonColor}` : "") +
              (props.buttonInverted ? " is-inverted" : "") +
              (pending ? " is-loading" : "")
            }
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
}

export default SettingsPassword;
